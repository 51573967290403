.work-scheme {
  &__inner-wrap {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 480px) {
  .work-scheme {
    margin-bottom: 50px;
    &__inner-wrap {
      flex-direction: column;
    }
  }
}
