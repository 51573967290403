@import 'assets/style/_mixins.scss';

.lk-top {
  padding-top: 25px;
  margin-bottom: 107px;

  &__container {
  }

  &__return {
    margin-bottom: 64px;
  }

  &__main {
    display: flex;
  }

  &__user-info {
    padding-top: 17px;
  }

  &__avatar {
    margin-right: 65px;
  }

  &__name {
    font-size: 40px;
    color: #333b4c;
    @include bold();
    margin-bottom: 19px;
  }

  &__balance-block-list {

  }

  &__balance-block-wrap {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__balance-title {
    color: #727d84;
    font-size: 16px;
    @include regular();
    margin-bottom: 4px;
    line-height: 1;
  }

  &__balance-block {
    display: flex;
    align-items: center;
  }

  &__balance-item {
    margin-right: 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    &:last-child {
      margin-right: 0;
    }
  }

  &__balance-current {
    color: #333b4c;
    @include medium();
    font-size: 32px;
  }

  &__balance-difference {
    @include medium();
    font-size: 32px;

    &_positive {
      color: #388e3c;
    }
    &_negative {
      color: #f71937;
    }
  }

  &__balance-risk {
    font-size: 16px;
    @include regular();
    color: #333b4c;
  }
}

@media screen and (max-width: 780px) {
  .lk-top {
    margin-bottom: 30px;

    &__main {
      display: block;
    }

    &__avatar {
      width: 80px;
      height: 80px;
      min-width: 80px;
      margin-right: 20px;
    }

    &__name {
      font-size: 24px;
    }

    &__user-info {
      width: 100%;
      min-width: 100%;
      margin-bottom: 20px;
    }

    &__balance-block {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }

    &__balance-current {
      font-size: 24px;
    }

    &__balance-title {
      margin-bottom: 15px;
    }

    &__balance-difference {
      font-size: 24px;
    }
  }
}
