@import 'assets/style/_mixins.scss';

.lk-change-features-popup {
  max-width: 558px;
  width: 100%;

  &__loader {
    max-width: 448px;
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__title {
    color: #333B4C;
    text-align: center;
    margin-bottom: 28px;
    font-size: 24px;
    @include medium();
  }

  &__list {
    margin-bottom: 24px;
  }

  &__line {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }

  }

  &__line-title {
    color: #000000;
    font-size: 18px;
    @include regular();
    width: 268px;
    min-width: 268px;
    padding-right: 10px;

    &.css-1k21c49-container {
      height: auto !important;
    }
  }

  &__line-main {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: calc(100% - 268px - 10px);
  }

  &__line-amount {
    background: #F8F8F8;
    border-radius: 10px;
    height: 52px;
    width: 75px;
    padding: 10px;
    border: 1px solid #F8F8F8;

    color: #000000;
    font-size: 18px;
    @include regular();
    -webkit-appearance: none;
    line-height: normal !important;
    text-align: center;
    margin-right: 8px;
  }

  &__line-text {
    color: #000000;
    font-size: 18px;
    @include regular();
    margin-right: 24px;
  }

  &__line-remove-button {
    @include reset-button-styles();
    width: 58px;
    height: 52px;
    background: #F8F8F8;
    border-radius: 10px;
    min-width: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__line-remove-button-icon {

  }

  &__add-new-wrap {
    margin-bottom: 32px;
  }

  &__add-new {
    @include reset-button-styles();
    display: flex;
    align-items: center;

    &:disabled {
      cursor: default;
    }
  }

  &__add-new-icon-wrap {
    background: rgba(38, 86, 249, 0.06);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 58px;
    height: 52px;
    min-width: 58px;
    margin-right: 16px;

    .lk-change-features-popup__add-new:disabled & {
      background: rgba(47, 60, 68, 0.06);
    }
  }

  &__add-new-icon {
    transform: rotate(45deg);

    path {
      fill: #2656F9;

      .lk-change-features-popup__add-new:disabled & {
        fill: #393d41;
      }
    }
  }

  &__add-new-text {
    color: #2656F9;
    @include medium();
    font-size: 16px;

    .lk-change-features-popup__add-new:disabled & {
      color: #393d41;
    }
  }

  &__buttons-list {
    display: flex;
  }

  &__buttons-item {
    width: calc((100% - 16px) / 2);
    min-width: 190px;
    height: 50px;
    font-size: 14px;

    &:first-child {
      margin-right: 16px;
    }

    &:only-child {
      width: 100%;
      margin-right: 0;
    }
  }

  &__error-message {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    @include regular();
    font-size: 16px;
    height: 200px;
    background: rgba(38, 86, 249, 0.6);
    border-radius: 20px;
    padding: 20px;
    color: white;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 750px) {
  .lk-change-features-popup {

    &__line {
     &_new {
       flex-wrap: wrap;
     }
    }

    &__line-title {
      width: calc(100% - 185px);
      min-width: calc(100% - 185px);

      .lk-change-features-popup__line_new & {
        width: 100%;
        padding-right: 0;
        margin-bottom: 20px;
      }
    }

    &__line-main {
      justify-content: flex-start;
      width: 100%;
    }


    &__buttons-list {
      display: block;
    }

    &__buttons-item {
      width: 100%;
      min-width: 100%;

      &:first-child {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
  }
}
