@font-face {
  font-family: 'CoFoSans-Regular';
  font-display: swap;
  src: url('../fonts/CoFoSans-Regular.eot');
  src: local('☺'), url('../fonts/CoFoSans-Regular.woff') format('woff'),
    url('../fonts/CoFoSans-Regular.ttf') format('truetype'),
    url('../fonts/CoFoSans-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CoFoSans-Medium';
  font-display: swap;
  src: url('../fonts/CoFoSans-Medium.eot');
  src: local('☺'), url('../fonts/CoFoSans-Medium.woff') format('woff'),
    url('../fonts/CoFoSans-Medium.ttf') format('truetype'),
    url('../fonts/CoFoSans-Medium.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CoFoSans-Bold';
  font-display: swap;
  src: url('../fonts/CoFoSans-Bold.eot');
  src: local('☺'), url('../fonts/CoFoSans-Bold.woff') format('woff'),
    url('../fonts/CoFoSans-Bold.ttf') format('truetype'),
    url('../fonts/CoFoSans-Bold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CoFoSans-Black';
  font-display: swap;
  src: url('../fonts/CoFoSans-Black.eot');
  src: local('☺'), url('../fonts/CoFoSans-Black.woff') format('woff'),
    url('../fonts/CoFoSans-Black.ttf') format('truetype'),
    url('../fonts/CoFoSans-Black.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "HelveticaRegular";
  src: url("../fonts/HelveticaRegular.eot");
  src: url("../fonts/HelveticaRegular.eot?#iefix")format("embedded-opentype"),
  url("../fonts/HelveticaRegular.woff") format("woff"),
  url("../fonts/HelveticaRegular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}
