@import 'assets/style/_mixins.scss';
@import '~react-datepicker/dist/react-datepicker.css';

.calendar-button {
  @include reset-button-styles();
  width: 100px;
  position: relative;
  height: 30px;
  background: #D3E1EF;
  border-radius: 15px;
  margin-right: 10px;
  text-decoration: none;
  text-align: center;
  line-height: 30px;

  &:last-child {
    margin-right: 0;
  }

  &_active {
    background: #266BB0;
  }

  .react-datepicker-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    right: 0;
  }

  .react-datepicker__input-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    right: 0;
    bottom: 0;
  }

  .react-datepicker__month-wrapper {
    white-space: nowrap;
  }

  &__title {
    color: #444444;
    font-size: 16px;
    font-family: 'Helvetica', sans-serif;

    .calendar-button_active & {
      color: white;
    }
  }

  &__date-input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    opacity: 0;
    height: 30px;
    cursor: pointer;
    border: 0;
    padding: 0;
  }
}
