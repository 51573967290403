@import 'assets/style/_mixins.scss';

.partner-cta {
  border-radius: 32px;
  background: white;
  padding: 52px 120px 50px 60px;
  min-height: 440px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 20px 98px rgba(0, 34, 2, 0.15);
  background: url('../../../../assets/images/cta-partners-bg.jpg') no-repeat center center;
  background-size: cover;
  display: flex;

  &__content {
    max-width: 625px;
    width: 100%;
    position: relative;
    z-index: 20;
  }

  &__title {
    @include std-title();
    color: white;
    margin-bottom: 26px;
  }

  &__desc {
    margin-bottom: 50px;
  }

  &__desc-item {
    font-size: 16px;
    color: white;
    @include regular();
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__button-wrap {
    width: 200px;
  }

  &__img {
    margin-top: 30px;
  }
}

@media screen and (max-width: 1100px) {
  .partner-cta {
    padding: 30px;
    flex-direction: column-reverse;

    &__img {
      margin-top: 0;
      margin-bottom: 50px;
    }

    &__content {
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 680px) {
  .partner-cta {
    border-radius: 0;
    padding: 30px 20px;
  }
}