@import 'assets/style/_mixins.scss';

.trades {
  &__title {
    @include medium();
    color: white;
    font-size: 32px;
    margin-bottom: 46px;
  }

  &__list {
  }

  &__item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 8px;
    padding-bottom: 10px;
  }

  &__item-title {
    @include bold();
    color: white;
    font-size: 16px;
    width: calc(100% - 25% - 30%);
    word-break: break-word;
    padding-right: 5px;
  }

  &__item-percent {
    color: #1aa720;
    @include medium();
    font-size: 16px;
    width: 30%;
    text-align: center;

    &_negative {
      color: #ff3030;
    }
  }

  &__item-date {
    min-width: 90px;
    width: 25%;
    @include regular();
    color: white;
    font-size: 16px;
    text-align: right;
  }
}

@media screen and (max-width: 1100px) {
  .trades {
    &__title {
      font-size: 25px;
      margin-bottom: 20px;
    }
  }
}
