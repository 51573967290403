@import 'assets/style/_mixins.scss';
@import 'assets/style/_variables.scss';

.breadcrumbs {

  &__item {
    @include regular();
    color: #B3B4BA;
    font-size: 14px;
    text-decoration: none;
    display: inline-flex;

    &:first-child {
      &:after {
        content: '>';
        @include regular();
        display: block;
        margin: 0 3px;
        font-size: 14px;
        color: #B3B4BA;
      }
    }

    &:last-child {
      color: $black;
    }
  }
}
