@import 'assets/style/_mixins.scss';

.about-hero {
  background: $main-blue url('../../../../assets/images/top-bg.jpg') center center;
  background-size: cover;
  &__container {
    @include container();
    padding-top: $headerHeight;
    padding-bottom: 60px;
  }
  &__bread-crumbs {
    margin: 15px 0;
    .breadcrumbs__item:last-child {
      color: white;
    }
  }
  &__content {
    display: flex;
    flex-direction: row;
  }
  &__info-wrap {
    padding-top: 37px;
    width: 50%;
  }
  &__title {
    @include bold();
    color: white;
    font-size: 60px;
    margin-bottom: 10px;
  }
  &__info {
    margin-bottom: 48px;
    padding-right: 30px;
  }
  &__info-text {
    @include regular();
    color: white;
    font-size: 16px;
    line-height: 26px;
  }
  &__img-wrap {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__img {
    vertical-align: middle;
    max-width: 100%;
  }
}

@media screen and (max-width: 1000px) {
  .about-hero {
    &__container {
      padding-top: $headerHeight;
      padding-bottom: 60px;
    }
  }
}

@media screen and (max-width: 900px) {
  .about-hero {
    &__content {
      flex-direction: column-reverse;
    }
    &__info-wrap {
      padding-top: 30px;
      width: 100%;
    }
    &__title {
      font-size: 45px;
    }
    &__info {
      padding-right: 0;
    }
    &__img-wrap {
      width: 100%;
    }
  }
}

@media screen and (max-width: 580px) {
  .about-hero {
    &__container {
      padding-bottom: 30px;
    }
    &__title {
      font-size: 25px;
    }
    &__info {
      margin-bottom: 30px;
    }
  }
}