@import 'assets/style/_mixins.scss';

.iir-report-table {
  width: 100%;
  overflow-x: hidden;
  max-height: 400px;
  &__scroll-x-wrap {
    overflow-x: auto;
  }
  &__head {
    display: flex;
  }
  &__col {
    height: 40px;
    width: calc(100% / 6);
    min-width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 14px;
    @include regular();

    &_date {
      min-width: 170px;
    }

    .iir-report-table__head & {
      @include bold();
    }
  }
  &__account {
    min-width: 770px;
    margin-bottom: 35px;
  }
  &__account-title {
    font-size: 20px;
    @include medium();
    margin-bottom: 10px;
  }

  &__list {
    border-radius: 4px;
    background: rgba(49,128,69,.09);
    &_deals {
      background: rgba(6, 85, 164, 0.1);
    }
  }
  &__list-item {}
  &__list-item-inner {
    display: flex;
  }
  &__risk-text {
    @include helvetica();
    font-size: 12px;
    line-height: 1.5;
    color: rgba(39, 39, 39, 0.5);

    p {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__msg {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 120px;
    text-align: center;
    @include regular();
    font-size: 20px;
  }
}