@import 'assets/style/_mixins.scss';

.sms-content {
  &__title {
    margin-bottom: 20px;
    color: #333b4c;
    font-size: 32px;
    @include medium();
  }
  &__loader {
    display: flex;
    justify-content: center;
    margin-top: 35px;
  }
  &__form {}
  &__msg {
    color: $main-black;
    font-size: 18px;
    line-height: 1.3;
    @include regular();
    margin-bottom: 35px;
  }
  &__btn-wrap {
    display: flex;
    justify-content: center;
  }
  &__btn {
    width: calc((100% - 20px) / 2);
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
}

@media screen and (max-width: 750px) {
  .sms-content {
    &__btn {
      width: 100%;
    }
  }
}
