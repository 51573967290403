@import 'assets/style/_mixins.scss';

.select-account-form {
  &__msg {
    color: $main-black;
    font-size: 18px;
    line-height: 1.3;
    @include regular();
    margin-bottom: 35px;
  }
  &__msg-item {
    margin-bottom: 15px;

    a {
      color: $main-blue;
      &:hover {
        text-decoration: none;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__btn-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__btn {
    width: calc((100% - 20px) / 2);
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
}

@media screen and (max-width: 750px) {
  .select-account-form {
    &__btn-wrap {
      flex-direction: column-reverse;
    }

    &__btn {
      width: 100%;
      margin-right: 0;

      &:last-child {
        margin-bottom: 20px;
      }
    }
  }
}
