@import 'assets/style/_mixins.scss';

.lk-comment-popup {
  max-width: 558px;
  width: 100%;

  &__loader {
    max-width: 448px;
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__title {
    color: #333B4C;
    text-align: center;
    margin-bottom: 28px;
    font-size: 24px;
    @include medium();
  }

  &__textarea-holder {
    margin-bottom: 20px;
  }

  &__textarea {
    width: 100%;
    background: #F2F5FF;
    border-radius: 6px;
    -webkit-appearance: none;
    border: 1px solid #F2F5FF;
    padding: 20px 10px;
    @include regular();
    font-size: 16px;
    color: #333b4c;

    &:focus {
      border-color: #2656F9;
    }

    &::-webkit-input-placeholder {
      opacity: 1;
      color: #7c7c7c;
    }

    &::-moz-placeholder {
      opacity: 1;
      color: #7c7c7c;
    }

    &:-moz-placeholder {
      opacity: 1;
      color: #7c7c7c;
    }

    &:-ms-input-placeholder {
      opacity: 1;
      color: #7c7c7c;
    }

    &::-ms-input-placeholder {
      opacity: 1;
      color: #7c7c7c;
    }

    &::-ms-clear {
      display: none;
    }
  }

  &__buttons-list {
    display: flex;
  }

  &__buttons-item {
    width: calc((100% - 16px) / 2);
    min-width: 190px;
    height: 50px;
    font-size: 14px;

    &:first-child {
      margin-right: 16px;
    }

    &:only-child {
      width: 100%;
      margin-right: 0;
    }
  }

  &__message {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    @include regular();
    font-size: 16px;
    background: rgba(38, 86, 249, 0.6);
    border-radius: 20px;
    padding: 20px 10px;
    color: white;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 750px) {
  .lk-comment-popup {

    &__buttons-list {
      flex-direction: column;
    }

    &__buttons-item {
      width: 100%;
      min-width: 100%;

      &:first-child {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
  }
}
