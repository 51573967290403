@import 'assets/style/_mixins.scss';

.header {
  padding-bottom: 10px;
  position: relative;
  z-index: 20;
  box-sizing: border-box;
  background: url('../../../../assets/images/header-bg.jpg') center center;
  background-size: cover;

  &_light {
    background: transparent;
  }

  &_menu-open {
    background: white;
    z-index: 20;

    //&.header_light {
    //  height: 110px;
    //}
  }

  &_dropdown-open {
    z-index: 100;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: 100%;
  }

  &__logo {
    //height: 88px;
    //width: 106px;
    width: 139px;
    height: 114px;

    .header_light & {
      width: 139px;
      height: 114px;
    }
  }

  &__logo-img {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;

    path {

      .header_menu-open & {
        fill: #333b4c;
      }
    }
  }

  &__nav {
    margin-right: 33px;
  }

  &__nav-list {
    margin-left: 0;
    padding-left: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
  }

  &__nav-list-item-wrap {
    margin-right: 25px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__nav-list-item {
    @include reset-button-styles();
    @include regular();
    text-decoration: none;
    color: white;
    font-size: 16px;
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
  }

  &__avatar {
    @include reset-button-styles();
    width: 60px;
    height: 60px;
    text-decoration: none;
  }

  &__avatar-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__buttons {
    display: flex;
    margin-left: auto;
  }

  &__buttons-item {

    &:first-child {
      width: 158px;
      margin-right: 17px;
      &:hover {
        background: rgba(255, 255, 255, 0.7);
      }
    }

    &:last-child {
      width: 112px;
      color: $yellow;
    }
  }

  &__menu-toggle {
    @include reset-button-styles();
    transition-duration: 0.2s;
  }

  &__menu-toggle-icon {
    path {
      fill: white;
      .header_menu-open & {
        fill: #333b4c;
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .header {

    &__avatar {
      margin-right: 20px;
    }

    &__nav-list-item-wrap {
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }

    &__buttons-item {
      width: 150px;

      &:last-child {
        width: 120px;
        margin-left: 10px;
      }
    }
  }
}

@media screen and (max-width: 780px) {
  .header {
    padding-top: 10px;

    &__logo {
      height: 88px;
      width: 106px;

      .header_light & {
        height: 88px;
        width: 106px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .header {

    &__logo {
      width: 53px;
      height: 44px;

      .header_light & {
        width: 53px;
        height: 44px;
      }
    }
  }
}
