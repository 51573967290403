@import 'assets/style/_mixins.scss';

.connect-page {
  margin-bottom: 108px;
  &__container {}
  &__breadcrumbs {
    margin: 15px 0;
  }
  &__content {}
  &__title {
    @include std-title();
    margin-bottom: 80px;
    line-height: 1.3;
  }
  &__work-scheme {
    margin-bottom: 70px;
  }
  &__btn-wrap {
    text-align: center;
    margin-bottom: 120px;
  }
  &__btn {
    display: inline-flex;
    padding: 0 40px;
  }
  &__form-wrap {
    @include container();
  }
}

@media screen and (max-width: 980px) {
  .connect-page {
    margin-bottom: 80px;
    &__title {
      margin-bottom: 60px;
    }
    &__work-scheme {
      margin-bottom: 50px;
    }
    &__btn-wrap {
      margin-bottom: 100px;
    }
  }
}

@media screen and (max-width: 680px) {
  .connect-page {
    margin-bottom: 0;
    &__form-wrap {
      padding: 0;
    }
    &__form {
      border-radius: 0;
    }
  }
}

@media screen and (max-width: 580px) {
  .connect-page {
    &__title {
      margin-bottom: 50px;
    }
    &__work-scheme {
      margin-bottom: 0;
    }
    &__btn-wrap {
      margin-bottom: 50px;
    }
  }
}

@media screen and (max-width: 580px) {
  .connect-page {
    &__work-scheme {
      margin-bottom: 50px;
    }
  }
}