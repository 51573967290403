@import 'assets/style/_mixins.scss';
@import 'assets/style/_variables.scss';

.partner-item {
  padding: 110px 0 30px 0;
  position: relative;

  &.partner__item_text {
    padding: 0;
    width: calc(100% - ((100% - 84px * 2) / 3) - 84px);
    margin: 0;
  }

  &__icon-wrap {
    width: 90px;
    height: 90px;
    border-radius: 23px;
    background: white;
    right: 2px;
    top: 32px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;

    .partner__item:nth-child(2) & {
      right: -30px;
    }
  }

  &__icon {
    min-width: 45px;
    min-height: 45px;
    max-width: 100%;
    max-height: 100%;

    path {
      stroke: #2058ba;
    }
  }

  &__content {
  }

  &__title {
    font-size: 40px;
    @include regular();
    color: $black;
    line-height: 1.1;
    margin-bottom: 10px;
  }

  &__desc {
    @include regular();
    font-size: 18px;
    color: $black;
  }

  &__info {
    color: #727d84;
    font-size: 16px;
    @include regular();
    line-height: 1.5;
    margin-top: 26px;
  }

  &__logo {
    width: 100%;
    max-width: 244px;
    height: 62px;
    margin-bottom: 13px;
  }

  &__link-wrap {
  }

  &__link {
    font-size: 16px;
    color: #2058ba;
    @include regular();
    &:hover {
      text-decoration: none;
    }
  }
  &__license {
    border-radius: 24px;
    overflow: hidden;
    background-color: #F9F9F9;
    box-shadow: 0 20px 98px rgba(0, 34, 2, 0);
    transition: box-shadow 0.3s ease-in-out;
    &:hover {
      box-shadow: 0 20px 98px rgba(0, 34, 2, 0.15);
    }
  }
  &__license-link {
    text-decoration: none;
  }
  &__license-wrap {
    display: flex;
    flex-direction: row;
  }
  &__license-img-wrap {
    width: 50%;
  }
  &__license-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    vertical-align: middle;
  }
  &__license-text-wrap {
    width: 50%;
    padding: 40px;
  }
  &__license-text {
    @include regular();
    font-size: 14px;
    line-height: 1.5;
    color: #727d84;
  }
}

@media screen and (max-width: 1170px) {
  .partner-item {
    padding: 104px 0 30px 0;

    &__icon-wrap {
      width: 60px;
      height: 60px;
      border-radius: 12px;
    }

    &__icon {
      min-width: 25px;
      min-height: 25px;
      max-width: 35px;
      max-height: 35px;
    }

    &__title {
      font-size: 28px;
    }

    &__license-text {
      font-size: 16px;
    }
  }
}

@media screen and (max-width: 880px) {
  .partner-item {
    background: #f5f5f5;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 104px 15px 30px 30px;

    &__icon-wrap {
      right: 32px;

      .partner__item:nth-child(2) & {
        right: 32px;
      }
    }
    &__title {
      margin-right: 90px;
    }
    &__license {
      border-radius: 10px;
    }
    &__license-wrap {
      flex-direction: column;
    }
    &__license-img-wrap {
      width: 100%;
    }
    &__license-text-wrap {
      width: 100%;
    }
  }
}

@media screen and (max-width: 480px) {
  .partner-item {
    &__license-img-wrap {
      width: 100%;
    }
    &__license-text-wrap {
      width: 100%;
      padding: 20px 15px;
    }
  }
}
