@import 'assets/style/_mixins.scss';

.close-all-positions-popup {

  &__title {
    margin-bottom: 20px;
    color: #333b4c;
    font-size: 32px;
    @include medium();
  }

  &__desc {
    margin-bottom: 35px;
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__list-item {
    width: calc((100% - 20px) / 2);
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 580px;
  }
}

@media screen and (max-width: 600px) {
  .close-all-positions-popup {

    &__loader {
      min-width: auto;
      max-width: 400px;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    }
  }
}


@media screen and (max-width: 480px) {
  .close-all-positions-popup {

    &__list {
      flex-direction: column-reverse;
    }

    &__list-item {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;

      &:first-child {
        margin-bottom: 0;
      }
    }
  }
}
