@import 'assets/style/_variables.scss';
@import 'assets/style/_mixins.scss';

.homepage {
  padding-bottom: 100px;
  position: relative;

  &__top-banner {
    position: relative;
    z-index: 10;
  }

  &__bottom {
    background: #F8F8F8;
    padding: 89px 0;
  }

  &__about-company {
    max-width: 1031px + 80px;
    width: 100%;
    margin: 0 auto 60px auto;
    padding: 0 40px;
  }

  &__form-wrap {
    position: relative;
    z-index: 20;
    @include container();
  }
}

@media screen and (max-width: 680px) {
  .homepage {
    padding-bottom: 0;

    &__top-banner-wrap {
      padding-left: 0;
      padding-right: 0;
    }

    &__bottom {
      padding-bottom: 0;
    }

    &__about-company {
      padding: 0 12px;
    }

    &__form-wrap {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
