@import 'assets/style/_mixins.scss';

.sms-form {
  margin: 0 auto;
  &__content {
    display: flex;
    flex-direction: column;
  }
  &__code {
    width: 100%;
  }
  &__code-title {
    @include regular();
    font-size: 14px;
    line-height: 1.3;
    color: $grey;
    margin-bottom: 10px;
    text-align: center;
  }
  &__code-input-holder {}
  &__code-input {
    width: 100%;
    height: 50px;
    border-radius: 32px;
    border: 1px solid rgba(0, 7, 23, 0);
    background: rgba(0, 7, 23, .1);
    -webkit-appearance: none;
    padding: 0 34px;
    line-height: normal !important;
    outline: none;
    text-align: center;

    font-size: 16px;
    color: rgba(0, 7, 23, 1);
    @include regular();

    &::-webkit-input-placeholder {
      opacity: 1;
      color: rgba(0, 7, 23, .3);
    }

    &::-moz-placeholder {
      opacity: 1;
      color: rgba(0, 7, 23, .3);
    }

    &:-moz-placeholder {
      opacity: 1;
      color: rgba(0, 7, 23, .3);
    }

    &:-ms-input-placeholder {
      opacity: 1;
      color: rgba(0, 7, 23, .3);
    }

    &::-ms-input-placeholder {
      opacity: 1;
      color: rgba(0, 7, 23, .3);
    }

    &::-ms-clear {
      display: none;
    }

    &:focus {
      border-color: rgba(0, 7, 23, .1);
    }
  }
  &__resend-msg {
    margin-top: 8px;
    text-align: center;
    @include regular();
    font-size: 14px;
    color: #1C3462;
  }
  &__resend-btn-wrap {
    text-align: center;
  }
  &__resend-btn {
    @include regular();
    @include reset-button-styles();
    padding: 5px 8px;
    font-size: 14px;
    color: #1C3462;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  &__doc-list {
    margin: 20px 0;
  }
  &__doc-list-item {
    line-height: 1.2;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__doc-list-item-link {
    word-break: break-word;
    @include regular();
    font-size: 16px;
    text-decoration: none;
    color: #000717;
    transition: color 300ms ease;
    &:hover {
      color: $hover-blue;
    }
  }
  &__btn-wrap {
    margin-top: 25px;
    display: flex;
  }
  &__btn {
    width: calc((100% - 20px) / 2);
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
}

@media screen and (max-width: 750px) {
  .sms-form {
    &__btn-wrap {
      flex-direction: column-reverse;
    }

    &__btn {
      width: 100%;
      margin-right: 0;

      &:last-child {
        margin-bottom: 20px;
      }
    }
  }
}