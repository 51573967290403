@import 'assets/style/_mixins.scss';
@import 'assets/style/_variables.scss';

.partner {
  padding-top: 93px;
  padding-bottom: 79px;

  &__container {
    @include container();
  }

  &__title {
    @include std-title();
    margin-bottom: 16px;
  }

  &__desc {
    margin-bottom: 28px;
    font-size: 16px;
    line-height: 1.5;
    @include regular();
    color: $black;
  }

  &__main {
  }

  &__list {
  }

  &__list-line {
    border-bottom: 1px solid rgba(0,0,0,.1);

    &:last-child {
      border-bottom: none;
    }
  }

  &__list-line-container {
    display: flex;
    @include container();
  }

  &__item {
    width: calc((100% - 84px * 2) / 3);
    position: relative;
    margin-right: 84px;

    &:nth-child(3) {
      margin-right: 0;
      padding-left: 25px;
    }

    //.partner__list-line:nth-child(2) & {
    //  padding-top: 70px;
    //}
  }
}

@media screen and (max-width: 1170px) {
  .partner {

   &__item {

     .partner__list-line:last-child & {

       &:last-child {
         width: 50%;
         &.partner__item_text {
           width: 100%;
         }
       }
     }
   }
    &__list-line-container_wide {
      flex-direction: column;
    }
  }
}

@media screen and (max-width: 980px) {
  .partner {
    &__item {
      width: calc((100% - 60px * 2) / 3);
      position: relative;
      margin-right: 60px;

      &.partner__item_text {
        width: calc(100% - ((100% - 84px * 2) / 3) - 84px);
        margin-right: 0;
      }
    }
  }
}

@media screen and (max-width: 880px) {
  .partner {

    &__list-line {
      border-bottom: none;
    }

    &__list-line-container {
      display: block;
    }

    &__item {
      width: 100%;
      margin-right: 0;

      .partner__list-line:last-child & {

        &:last-child {
          width: 100%;
        }
      }
    }
  }
}
