@import 'assets/style/_mixins.scss';
@import 'assets/style/_variables.scss';

.features {
  background: darken(#F8F8F8, 3%);
  padding-top: 100px;
  padding-bottom: 126px;

  &__container {
    @include container();
  }

  &__title {
   @include std-title();
    margin-bottom: 28px;
    line-height: 1.2;
  }

  &__list {
    display: flex;
  }

  &__item {
    width: 100%;
    max-width: 319px;

    &:first-child {
      margin-right: 73px;
    }

    &:nth-child(2) {
      margin-right: 137px;
    }
  }

  &__item-icon-wrap {
    margin-bottom: 16px;
    width: 60px;
    height: 60px;
  }

  &__item-icon {
    max-width: 100%;
    max-height: 100%;
  }

  &__item-title {
    font-size: 16px;
    @include bold();
    color: $black;
    margin-bottom: 21px;
  }

  &__item-desc {
    font-size: 14px;
    @include regular();
    color: $black;
    line-height: 1.52;
  }
}

@media screen and (max-width: 900px) {
  .features {
    padding-top: 50px;
    padding-bottom: 50px;

    &__title {
      margin-bottom: 30px;
    }

    &__list {
      display: block;
    }

    &__item {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      margin-bottom: 40px;

      &:first-child {
        padding-right: 0;
      }

      &:last-child {
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 0;
      }
    }
  }
}
