@import 'assets/style/_mixins.scss';

.activate-strategy-auth-popup {
  .popup-wrap__inner {
    max-width: 520px;
  }
  &__title {
    text-align: center;
    margin-bottom: 20px;
    color: $black;
    font-size: 24px;
    @include medium();
  }
  &__info {
    margin: 0 20px 40px;
    text-align: center;
    color: $main-black;
    font-size: 18px;
    line-height: 1.3;
    @include regular();
  }
  &__footer {
    display: flex;
  }
  &__btn {
    width: calc((100% - 20px) / 2);
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
}
