@import 'assets/style/_mixins.scss';
@import 'assets/style/_variables.scss';

.user-form {
  background: url('../../../../assets/images/form-bg.png') center center;
  background-size: cover;
  width: 100%;
  border-radius: 32px;
  padding: 44px 74px 62px 80px;

  &__content {
    margin-bottom: 44px;
  }

  &__title {
    @include std-title();
    color: #ffffff;
    margin-bottom: 16px;

    .user-form_partners & {
      text-align: center;
      margin-bottom: 23px;
    }
  }

  &__text {
    font-size: 16px;
    color: white;
    @include regular();
    line-height: 1.6;
    letter-spacing: 0.7px;

    .user-form_partners & {
      text-align: center;
    }
  }

  &__form {
  }

  &__form-line {
    display: flex;

    &:first-child {
      margin-bottom: 30px;
    }

    .user-form_partners & {
      flex-wrap: wrap;

      &:last-child {
        flex-direction: row-reverse;
      }
    }
  }

  &__input-holder {
    width: calc((100% - 16px * 2) / 3);
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }

    .user-form_partners & {
      width: calc((100% - 16px) / 2);
      margin-bottom: 16px;

      &:nth-child(2n) {
        margin-right: 0;
      }

      &:nth-last-child(-n + 2) {
        margin-bottom: 0;
      }
    }
  }

  &__input {
    width: 100%;
    height: 50px;
    border-radius: 32px;
    border: 1px solid rgba(244, 244, 244, 0.1);
    background: rgba(255, 255, 255, 0.1);
    -webkit-appearance: none;
    padding: 0 34px;
    line-height: normal !important;
    outline: none;

    font-size: 16px;
    color: white;
    @include regular();

    &::-webkit-input-placeholder {
      opacity: 1;
      color: white;
    }

    &::-moz-placeholder {
      opacity: 1;
      color: white;
    }

    &:-moz-placeholder {
      opacity: 1;
      color: white;
    }

    &:-ms-input-placeholder {
      opacity: 1;
      color: white;
    }

    &::-ms-input-placeholder {
      opacity: 1;
      color: white;
    }

    &::-ms-clear {
      display: none;
    }

    &:focus {
      border-color: white;
    }
  }

  &__input-error {
    @include regular();
    font-size: 12px;
    color: #ec3939;
  }

  &__button {
    width: calc((100% - 16px * 2) / 3);
    margin-right: 36px;

    .user-form_partners & {
      margin-right: 0;
      width: calc((100% - 16px) / 2);
    }
  }

  &__privacy {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .user-form_partners & {
      margin-right: 16px;
      width: calc((100% - 16px) / 2);
      padding-left: 34px;
    }
  }

  &__privacy-checkbox-wrap {
    width: 24px;
    height: 24px;
    position: relative;
    min-width: 24px;
    margin-right: 8px;
  }

  &__privacy-checkbox {
    @include reset-button-styles();
    width: 24px;
    height: 24px;
    border-radius: 2px;
    background: white;
  }

  &__privacy-checkbox-icon {
    position: absolute;
    top: 7px;
    left: 6px;
    width: 13px;
    height: 10px;
    pointer-events: none;
  }

  &__privacy-text {
    @include helvetica();
    color: white;
    font-size: 12px;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  &__message {
    margin-top: 30px;
    @include regular();
    font-size: 16px;
    color: #333b4c;
    line-height: 1.3;
    padding: 20px;
    border-radius: 10px;

    &_success {
      background: #bbffb9;
    }
    &_fail {
      background: #ffbcb7;
    }
  }
}

@media screen and (max-width: 1100px) {
  .user-form {
    padding: 50px 20px;
  }
}

@media screen and (max-width: 980px) {
  .user-form {
    &__title {
      .user-form_partners & {
        text-align: left;
      }
    }

    &__text {
      .user-form_partners & {
        text-align: left;
      }
    }

    &__form-line {
      flex-direction: column-reverse;

      &:first-child {
        flex-direction: column;
      }

      .user-form_partners & {
        &:last-child {
          flex-direction: column-reverse;
        }
      }
    }

    &__input-holder {
      width: 100%;
      max-width: 70%;
      margin-right: 0;
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }

      .user-form_partners & {
        width: 100%;
        max-width: 70%;

        &:nth-last-child(-n + 2) {
          margin-bottom: 16px;
        }
      }
    }

    &__button {
      width: 100%;
      max-width: 70%;
      margin-right: 0;

      .user-form_partners & {
        width: 100%;
        max-width: 70%;
      }
    }

    &__privacy {
      margin-bottom: 16px;
      padding-left: 10px;

      .user-form_partners & {
        padding-left: 10px;
        margin-right: 0;
        width: 100%;
        max-width: 70%;
      }
    }
  }
}

@media screen and (max-width: 680px) {
  .user-form {

    &_partners {
      margin-right: -27px;
      margin-left: -27px;
      width: calc(100% + 54px);
    }

    &__title {
      font-size: 30px;
      margin-bottom: 10px;

      .user-form_partners & {
        text-align: left;
      }
    }

    &__input-holder {
      max-width: 100%;

      .user-form_partners & {
        max-width: 100%;
      }
    }

    &__button {
      max-width: 100%;

      .user-form_partners & {
        max-width: 100%;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .user-form {
    &_partners {
      margin-right: -12px;
      margin-left: -12px;
      width: calc(100% + 24px);
    }
  }
}
