@import 'assets/style/_variables.scss';
@import 'assets/style/_mixins.scss';

.contacts {
  padding-top: 21px;
  padding-bottom: 100px;

  &__breadcrumbs {
    margin-bottom: 36px;
  }

  &__container {
  }

  &__title {
    @include std-title();
    margin-bottom: 64px;
    line-height: 1;
  }

  &__main {
    display: flex;
    justify-content: space-between;
    //align-items: flex-start;
  }

  &__info-wrap {
    width: calc((100% - 85px - 50px) / 2);
    margin-right: 85px;
    padding-top: 30px;
  }

  &__item {
    margin-bottom: 67px;

    &:nth-child(2) {
      margin-bottom: 50px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__item-heading {
    color: $black;
    font-size: 32px;
    @include medium();
    margin-bottom: 24px;

    .contacts__item:last-child & {
      margin-bottom: 15px;
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
  }

  &__content-item {
    margin-right: 35px;
    margin-bottom: 34px;
    width: calc((100% - 35px + 50px) / 2);

    &:nth-child(2n) {
      margin-right: 0;
      width: calc((100% - 35px - 50px) / 2);
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__content-key {
    color: #b3b4ba;
    font-size: 14px;
    @include regular();
    margin-bottom: 8px;
  }

  &__content-value {
    font-size: 18px;
    color: $black;
    @include regular();
    line-height: 1.55;
  }

  &__content-value-link {
    color: #333b4c;
    font-size: 18px;
    @include regular();
    text-decoration: none;

    &_blue {
      color: #2656f9;
    }
  }

  &__info {
    color: #333b4c;
    font-size: 16px;
    line-height: 25px;
    @include regular();
    margin-bottom: 20px;
  }

  &__map {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc((100% - 85px + 50px) / 2);
    background: #f7f7f7;
  }
}

@media screen and (max-width: 1000px) {
  .contacts {

    &__main {
      flex-direction: column-reverse;
    }

    &__info-wrap {
      width: 100%;
      margin-right: 0;
      padding-top: 0;
    }

    &__item {
      margin-bottom: 30px;

      &:nth-child(2) {
        margin-bottom: 30px;
      }
    }

    &__item-heading {
      font-size: 28px;
    }

    &__map {
      width: 100%;
      height: 300px;
      margin-bottom: 30px;
    }
  }
}

@media screen and (max-width: 600px) {
  .contacts {
    padding-bottom: 50px;

    &__title {
      margin-bottom: 30px;
    }

    &__item-heading {
      font-size: 23px;
      margin-bottom: 15px;
      line-height: 1.1;
    }

    &__content-item {
      margin-right: 0;
      margin-bottom: 15px;
      width: 100%;

      &:nth-child(2n) {
        width: 100%;
      }
    }

    &__content-key {
      margin-bottom: 0;
    }
  }
}
