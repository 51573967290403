@import 'assets/style/_mixins.scss';

.become-a-partner-form {
  padding: 40px;
  border: 2px solid #F8F8F8;
  border-radius: 32px;
  &__content {
    max-width: 716px;
    margin: 0 auto;
  }
  &__head {
    text-align: center;
    margin-bottom: 30px;
  }
  &__title {
    @include std-title();
    margin-bottom: 20px;
  }
  &__sub-title {
    @include regular();
    font-size: 16px;
    line-height: 1.3;
    color: #000717;
  }
  &__body {
    margin-bottom: 24px;
    display: flex;
    flex-direction: row;
  }
  &__body-col {
      width: calc((100% - 16px) / 2);
    &_fields {
      margin-right: 8px;
    }
    &_upload-file {
      margin-left: 8px;
    }
  }
  &__field {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__input-holder {}
  &__input {
    width: 100%;
    height: 50px;
    border-radius: 32px;
    border: 1px solid rgba(0, 7, 23, 0);
    background: rgba(0, 7, 23, .1);
    -webkit-appearance: none;
    padding: 0 34px;
    line-height: normal !important;
    outline: none;

    font-size: 16px;
    color: rgba(0, 7, 23, 1);
    @include regular();

    &::-webkit-input-placeholder {
      opacity: 1;
      color: rgba(0, 7, 23, .3);
    }

    &::-moz-placeholder {
      opacity: 1;
      color: rgba(0, 7, 23, .3);
    }

    &:-moz-placeholder {
      opacity: 1;
      color: rgba(0, 7, 23, .3);
    }

    &:-ms-input-placeholder {
      opacity: 1;
      color: rgba(0, 7, 23, .3);
    }

    &::-ms-input-placeholder {
      opacity: 1;
      color: rgba(0, 7, 23, .3);
    }

    &::-ms-clear {
      display: none;
    }

    &:focus {
      border-color: rgba(0, 7, 23, .1);
    }
  }
  &__input-error {
    @include regular();
    font-size: 14px;
    color: #ec3939;
    margin-top: 5px;
  }
  &__privacy {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  &__privacy-checkbox-wrap {
    width: 24px;
    height: 24px;
    position: relative;
    min-width: 24px;
    margin-right: 8px;
  }
  &__privacy-checkbox {
    @include reset-button-styles();
    width: 24px;
    height: 24px;
    border-radius: 2px;
    background: rgba(0, 7, 23, .1);
  }
  &__privacy-checkbox-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 13px;
    height: 10px;
    pointer-events: none;
    path {
      stroke: #000717;
    }
  }
  &__privacy-text {
    @include helvetica();
    color: #000717;
    font-size: 12px;
    line-height: 1.3;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  &__footer {}
  &__btn {
    width: 100%;
  }
  &__message {
    margin-top: 30px;
    @include regular();
    font-size: 16px;
    color: #333b4c;
    line-height: 1.3;
    padding: 20px;
    border-radius: 10px;

    &_success {
      background: #bbffb9;
    }
    &_fail {
      background: #ffbcb7;
    }
  }
}

@media screen and (max-width: 880px) {
  .become-a-partner-form {
    &__body {
      margin-bottom: 24px;
      flex-direction: column;
    }
    &__body-col {
      width: 100%;
      &_fields {
        margin-right: 0;
        margin-bottom: 35px;
      }
      &_upload-file {
        margin-left: 0;
      }
    }
  }
}

@media screen and (max-width: 580px) {
  .become-a-partner-form {
    padding: 30px 20px;
  }
}