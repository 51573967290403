@import './_variables.scss';

@mixin reset-button-styles() {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

// Fonts
@mixin regular() {
  font-family: 'CoFoSans-Regular', sans-serif;
}

@mixin medium() {
  font-family: 'CoFoSans-Medium', sans-serif;
}

@mixin bold() {
  font-family: 'CoFoSans-Bold', sans-serif;
}

@mixin black() {
  font-family: 'CoFoSans-Black', sans-serif;
}


@mixin helvetica() {
  font-family: 'HelveticaRegular', sans-serif;
}

@mixin container() {
  max-width: 1242px + 80px;
  width: 100%;
  margin: 0 auto;
  padding: 0 40px;


  @media screen and (max-width: 1000px) {
    padding: 0 27px;
  }

  @media screen and (max-width: 480px) {
    padding: 0 12px;
  }
}


// Animation for blocks from bottom to top DELAYED
@mixin from-bottom-to-top() {
  &-enter {
    transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
    opacity: 0;
    transform: translateY(30px);
  }
  &-enter-done {
    transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
    opacity: 1;
    transform: translateY(0);
  }
  &-exit {
    transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
    opacity: 1;
    transform: translateY(30px);
  }
  &-exit-active {
    transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
    opacity: 0;
    transform: translateY(30px);
  }
}

@mixin std-title() {
  font-size: 40px;
  @include bold();
  color: $black;

  @media screen and (max-width: 900px) {
    font-size: 35px;
  }

  @media screen and (max-width: 580px) {
    font-size: 25px;
  }
}
