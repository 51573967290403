@import 'assets/style/_mixins.scss';

.strategies-list {
  padding-top: 95px;
  padding-bottom: 88px;

  &__container {
  }

  &__heading {
    @include std-title();
    margin-bottom: 25px;
  }

  &__filters {
    margin-bottom: 40px;
  }

  &__main {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  &__item {
    width: calc((100% - 30px) / 2);
    margin-right: 30px;
    margin-bottom: 40px;

    &:nth-child(2n) {
      margin-right: 0;
    }

    &:nth-last-child(-n + 1) {
      margin-bottom: 0;
    }
  }

  &__loader {
    min-height: 300px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__footer {
    text-align: center;
    padding: 50px 16px 0;
  }
  &__show-all-btn {
    display: inline-flex;
    padding: 0 45px;
  }
}

/*@media screen and (max-width: 1160px) {
  .strategies-list {
    &__item {
      width: calc((100% - 30px) / 2);

      &:nth-child(3n) {
        margin-right: 30px;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }

      &:nth-last-child(-n + 1) {
        margin-bottom: 0;
      }
    }
  }
}*/

@media screen and (max-width: 1080px) {
  .strategies-list {
    &__item {
      width: 100%;
      margin-right: 0;

      &:nth-child(2n) {
        margin-right: 0;
      }

      &:nth-child(3n) {
        margin-right: 0;
      }

      &:nth-last-child(-n + 2) {
        margin-bottom: 30px;
      }

      &:nth-last-child(-n + 1) {
        margin-bottom: 30px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .strategies-list {
    &__show-all-btn {
      width: 100%;
    }
  }
}
