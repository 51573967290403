@import 'assets/style/_mixins.scss';

.activate-strategy-popup {
  .popup-wrap__inner {
    width: 520px;
  }
  &__title {
    text-align: center;
    margin-bottom: 20px;
    color: $black;
    font-size: 24px;
    @include medium();
    &_error {
      color: #D32F2F;
    }
    &_success {
      color: #1AA720;
    }
  }
  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 35px;
  }
}

@media screen and (max-width: 750px) {
  .activate-strategy-popup {
    .popup-wrap__inner {
      width: 400px;
    }
  }
}

@media screen and (max-width: 480px) {
  .activate-strategy-popup {
    .popup-wrap__inner {
      width: 300px;
    }
  }
}