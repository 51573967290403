@import 'assets/style/_mixins.scss';

.mobile-menu {
  position: absolute;
  top: 167px !important;
  left: 0;
  right: 0;
  bottom: 0;

  &>div {
    width: 100%;
    bottom: 0;
    height: 100%;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    border-top: 1px solid #dae1e3;
    padding-top: 40px;
  }

  &__nav {
    margin-bottom: 40px;
  }

  &__nav-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 0;
    padding-left: 0;
    list-style-type: none;
  }

  &__nav-list-item-wrap {
    margin-bottom: 40px;
    text-align: center;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__nav-list-item {
    @include reset-button-styles();
    text-decoration: none;
    font-family: 'CoFoSans-Regular', sans-serif;
    color: #444444;
    font-size: 20px;
    line-height: 1.5;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__buttons-item {
    border-radius: 5px;
    font-size: 16px;
    font-weight: normal;
    padding: 0 25px;
    width: fit-content;

    &:last-child {
      margin-left: 10px;
    }
  }
}

@media screen and (max-width: 780px) {
  .mobile-menu {
    top: 108px !important;
  }
}

@media screen and (max-width: 600px) {
  .mobile-menu {
    top: 63px !important;
  }
}

@media screen and (max-width: 480px) {
  .mobile-menu {

    &__buttons {
      display: block;
      padding-left: 20px;
      padding-right: 20px;
    }

    &__buttons-item {
      width: 100%;
      margin-bottom: 20px;

      &:last-child {
        margin-left: 0;
      }
    }
  }
}
