@import 'assets/style/_mixins.scss';
@import 'assets/style/_variables.scss';

.profit {
  display: flex;
  align-items: center;

  &__value {
    font-size: 24px;
    @include medium();
    color: #2058BA;
    line-height: 1;
    margin-right: 5px;
    flex-shrink: 0;

    &_negative {
      color: #8e3838;
    }
  }

  &__text {
    @include regular();
    color: #727d84;
    font-size: 12px;
    max-width: 196px;
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .profit {

    &__value {
      font-size: 28px;
      white-space: nowrap;
    }

    &__text {
     word-break: break-word;
    }
  }
}
