@import 'assets/style/_mixins.scss';

.avatar-upload {
  position: relative;
  width: 183px;
  height: 183px;

  &__avatar-wrap {
    width: 183px;
    height: 183px;
    box-shadow: 0px 0px 12px rgba(49, 47, 70, 0.15);
    border-radius: 50%;
    overflow: hidden;
  }

  &__avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    overflow: hidden;
  }

  &__avatar-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40px;
    height: 36px;
    margin-top: -18px;
    margin-left: -20px;
    pointer-events: none;
  }

  &__avatar-input {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    cursor: pointer;
  }

  &__avatar-loading {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: var(--bluey-grey);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__dropzone-wrap {
    width: 183px;
    height: 183px;
    border-radius: 50%;
    position: relative;

    &_error {
      border-color: var(--reddish-pink);
    }

    section {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      outline: none;

      div {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        outline: none;
      }
    }
  }
}

@media screen and (max-width: 780px) {
  .avatar-upload {
    width: 80px;
    height: 80px;

    &__avatar-wrap {
      width: 80px;
      height: 80px;
    }

    &__dropzone-wrap {
      width: 80px;
      height: 80px;
    }
  }
}
