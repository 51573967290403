@import 'assets/style/_mixins.scss';

.activate-strategy-result {
  &__success {
    text-align: center;
    margin-bottom: 35px;
  }
  &__success-icon {
    vertical-align: middle;
  }
  &__msg {
    color: $main-black;
    font-size: 18px;
    line-height: 1.3;
    @include regular();
    margin-bottom: 35px;
  }
  &__btn-wrap {
    display: flex;
    justify-content: center;
  }
  &__btn {
    width: calc((100% - 20px) / 2);
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
}

@media screen and (max-width: 750px) {
  .activate-strategy-result {
    &__btn {
      width: 100%;
    }
  }
}