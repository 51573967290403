@import 'assets/style/_mixins.scss';

.author-info {
  background: white;
  box-shadow: 0 12px 34px rgba(65, 62, 101, 0.102519);
  border-radius: 30px;
  padding: 50px 76px 50px 50px;
  display: flex;

  &__main {
    display: flex;
  }

  &__top {
    margin-bottom: 26px;
    width: 183px;
    min-width: 183px;
    margin-right: 48px;
  }

  &__photo {
    width: 183px;
    height: 183px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 22px;
  }

  &__name {
    font-size: 16px;
    @include bold();
    color: $black;
    text-align: center;
  }

  &__info {
    width: calc(100% - 183px - 48px);
  }

  &__info-title {
    color: #333B4C;
    font-size: 24px;
    @include medium();
    margin-bottom: 15px;
  }

  &__info-content {
    font-size: 14px;
    color: #333B4C;
    @include regular();
  }

}

@media screen and (max-width: 800px) {
  .author-info {
    box-shadow: none;
    border-radius: 0;
    padding: 0;

    .managers-list & {
      box-shadow: 0 12px 34px rgba(65, 62, 101, 0.102519);
      border-radius: 30px;
      padding: 20px;
    }
  }
}

@media screen and (max-width: 580px) {
  .author-info {
    padding: 20px;

    &__main {
      padding-right: 0;
      flex-direction: column;
    }

    &__top {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      margin-right: 0;
      flex-direction: column;
      align-items: flex-start;
    }

    &__info {
      width: 100%;
    }
  }
}
