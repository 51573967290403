@import 'assets/style/_mixins.scss';
@import 'assets/style/_variables.scss';

.lk-account-item {
  background: #FFFFFF;
  box-shadow: 0 20px 98px rgba(0, 34, 2, 0.08);
  border-radius: 16px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 40px 48px 48px 48px;

  &_no-strategy {
    flex-direction: column;
  }

  &__left {
    width: 50%;
    padding-right: 20px;
    //min-width: 450px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    //padding-top: 45px;

    .lk-account-item_no-strategy & {
      padding-top: 0;
    }
  }

  &__left-main {
    margin-bottom: 20px;
  }

  &__number {
    color: $black;
    font-size: 32px;
    @include medium();
    line-height: 1;
    margin-bottom: 14px;
  }

  &__title {
    color: $black;
    font-size: 24px;
    @include regular();
    margin-bottom: 21px;
  }

  &__balance-block {

  }

  &__balance-title {
    color: #727D84;
    font-size: 14px;
    @include regular();
  }

  &__balance-itself {
    color: $black;
    font-size: 32px;
    @include medium();
  }

  &__buttons {
    display: flex;
  }

  &__buttons-item {
    height: 50px;
    font-size: 14px;
    margin-right: 20px;
    flex: 1 0 auto;

    &:first-child {
      //width: 180px;
      //margin-right: 20px;
    }

    &:last-child {
      margin-right: 0;
      //width: calc(100% - 200px);
    }
  }

  &__right {
    width: 50%;
    //min-width: 508px + 60px;
    //max-width: 508px + 60px;
  }

  &__profit {
    padding-left: 60px;
    margin-bottom: 7px;
  }

  &__chart {
    margin-bottom: 13px;
  }

  &__portfolio-content {
    padding-left: 60px;
  }

  &__portfolio-content-title {
    font-size: 18px;
    color: #000717;
    @include  medium();
    line-height: 1;
    margin-bottom: 17px;
  }

  &__portfolio-content-list {
    display: flex;
    flex-wrap: wrap;
  }

  &__portfolio-content-item {
    display: flex;
    align-items: center;
    margin-right: 15px;

    &:after {
      content: ',';
      color: #000717;
      font-size: 16px;
      @include regular();
      line-height: 25px;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }

  &__portfolio-content-item-part {
    color: #000717;
    font-size: 16px;
    @include regular();
    line-height: 25px;
    margin-right: 6px;

    &:last-child {
      margin-right: 0;
    }
  }
}

@media screen and (max-width: 1220px) {
  .lk-account-item {
    display: block;

    &__left {
      min-width: 100%;
      width: 100%;
      justify-content: flex-start;
      margin-bottom: 30px;

      &:only-child {
        margin-bottom: 0;
      }
    }

    &__buttons {
      padding-left: 30px;
    }

    &__buttons-item {
      width: calc((100% - 20px * 2) / 3);

      &:first-child {
        //width: calc((100% - 20px) / 2);
      }

      &:last-child {
        //width: calc((100% - 20px) / 2);
      }
    }

    &__right {
      min-width: 100%;
      width: 100%;
      margin-left: -30px;
    }

    &__profit {
      padding-left: 30px;
    }

    &__portfolio-content {
      margin-bottom: 30px;
      padding-left: 30px;
    }
  }
}

@media screen and (max-width: 880px) {
  .lk-account-item {
    &__buttons {
      flex-direction: column;
    }
    &__buttons-item {
      width: 100%;
      //max-width: 70%;
      margin-right: 0;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media screen and (max-width: 580px) {
  .lk-account-item {
    padding: 37px 10px 20px 10px;

    &__buttons {
      display: block;
    }

    &__buttons-item {

      &:first-child {
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
      }

      &:last-child {
        width: 100%;
      }
    }
  }
}
