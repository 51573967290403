@import 'assets/style/_mixins.scss';
@import 'assets/style/_variables.scss';

.chart-nav {
  display: flex;
  width: auto;
  flex-wrap: wrap;
  position: relative;
  padding-bottom: 15px;

  &__item {
    @include reset-button-styles();
    color: #444444;
    font-size: 16px;
   @include helvetica();
/*
    &_active {
      background: #266BB0;
      color: white;
    }*/
  }

  &__tabs-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    border-bottom: 2px solid #EFEFEF;
    width: 100%;
  }

  &__tabs-bar-active {
    position: absolute;
    bottom: -1px;
    top: auto;
    left: 0;
    height: 2px;
    transition: all 0.3s ease;
    background:$brightBlue;
  }
}

@media screen and (max-width: 500px) {
  .chart-nav {

    &__item {
      font-size: 14px;
      word-break: break-word;
    }
  }
}

@media screen and (max-width: 500px) {
  .chart-nav {
   flex-wrap: wrap;

    &__item {
      margin-bottom: 10px;
    }
  }
}

@media screen and (max-width: 500px) {
  .chart-nav {
   flex-wrap: wrap;

    &__item {
      margin-bottom: 10px;
    }
  }
}
