@import 'assets/style/_mixins.scss';
@import 'assets/style/_variables.scss';

.strategies-page {
  margin-bottom: 108px;
  &__container {}
  &__breadcrumbs {
    margin: 15px 0;
  }
  &__list {
    padding-top: 0;
    padding-bottom: 108px;
  }
  &__form-wrap {
    @include container();
  }
}

@media screen and (max-width: 980px) {
  .strategies-page {
    margin-bottom: 70px;
    &__list {
      padding-bottom: 70px;
    }
  }
}

@media screen and (max-width: 680px) {
  .strategies-page {
    margin-bottom: 0;
    &__form-wrap {
      padding: 0;
    }
    &__form {
      border-radius: 0;
    }
  }
}