@import 'assets/style/_mixins.scss';

.faq {
  padding-top: 50px;
  &__title {
  }

  &__container {

  }

  &__container-inner {
  }

  &__list {
    display: flex;
    flex-direction: row;
    margin-bottom: 80px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__list-heading {
    width: 35%;
    color: #000717;
    font-size: 32px;
    @include medium();
    padding-right: 60px;
  }

  &__list-question-list {
    width: 65%;
  }
}

@media screen and (max-width: 1050px) {
  .faq {
    &__list-heading {
      font-size: 28px;
      padding-right: 30px;
    }
  }
}

@media screen and (max-width: 880px) {
  .faq {
    &__list {
      flex-direction: column;
      margin-bottom: 60px;
    }
    &__list-heading {
      width: 100%;
      padding-right: 0;
      text-align: center;
      margin-bottom: 30px;
    }
    &__list-question-list {
      width: 100%;
    }
  }
}

@media screen and (max-width: 580px) {
  .faq {
    padding-top: 30px;
    &__list {
      margin-bottom: 40px;
    }
    &__list-heading {
      font-size: 25px;
    }
  }
}
