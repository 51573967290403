@import 'assets/style/_mixins.scss';

.strategy-characteristic-item {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__title-wrap {
    display: flex;
    width: 50%;
  }

  &__title-icon-wrap {
    width: 20px;
    height: 18px;
    display: flex;
    align-items: center;
    margin-right: 16px;
  }

  &__title-icon {
    max-width: 100%;
    max-height: 100%;
  }

  &__title {
    color: white;
    font-size: 16px;
    @include medium();
  }

  &__value-wrap {
    display: flex;
    flex-wrap: wrap;
    width: 50%;
  }

  &__value {
    width: 50%;
    color: white;
    font-size: 16px;
    @include regular();

    .strategy-characteristic-item__value-wrap & {
      margin-right: 5px;
      width: auto;

      &:after {
        content: ',';
        color: white;
        font-size: 16px;
        @include regular();
      }

      &:last-child {
        margin-right: 0;

        &:after {
          display: none;
        }
      }
    }

    .portfolio-content & {
      padding-left: 10px;
      text-align: right;
    }
  }
}

@media screen and (max-width: 680px) {
  .strategy-characteristic-item {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .portfolio-content & {
      flex-direction: row;
      justify-content: space-between;
    }

    &__title-wrap {
      width: auto;
      margin-right: 10px;
    }

    &__title-icon-wrap {
      margin-right: 10px;
    }

    &__title {
      @include regular();

      &:after {
        content: ':';
      }
    }

    &__value-wrap {
      width: auto;

      &_with-icon {
        padding-left: 29px;
      }
    }

    &__value {
      width: auto;

      &_with-icon {
        padding-left: 29px;
      }

      .portfolio-content & {
        padding-left: 0;
      }
    }
  }
}
