@import 'assets/style/_mixins.scss';

.about {
  margin-bottom: 80px;
  margin-top: -$headerHeight;

  &__breadcrumbs {
    margin-bottom: 55px;
  }

  &__std-title {
    color: #333b4c;
    font-size: 40px;
    @include bold();
    line-height: 1;
    text-align: center;
    margin-bottom: 47px;

    .about__work & {
      margin-bottom: 32px;
    }
  }

  &__top {
    display: flex;
    width: 100%;
    margin-bottom: 95px;
  }

  &__top-content {
    max-width: 515px;
    margin-right: 140px;
    width: 100%;
  }

  &__title {
    color: #333b4c;
    font-size: 45px;
    @include bold();
    margin-bottom: 27px;
    line-height: 1;
  }

  &__top-desc {
    margin-bottom: 40px;
  }

  &__top-desc-item {
    font-size: 16px;
    line-height: 25px;
    color: #333b4c;
    @include regular();
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__top-button {
    width: 256px;
  }

  &__top-img-wrap {
    min-width: 397px;
    width: 397px;
    height: 423px;
    margin-top: -41px;
  }

  &__top-img {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__work {
    margin-bottom: 110px;
  }

  &__work-list {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  &__work-item {
    width: 313px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__work-item-icon-wrap {
    width: 64px;
    height: 64px;
    background: rgba(38, 86, 249, 0.06);
    border-radius: 50%;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 32px;
      right: -249px;
      width: 249px;
      height: 4px;
      background: rgba(38, 86, 249, 0.1);
    }

    .about__work-item:last-child & {
      &:after {
        display: none;
      }
    }
  }

  &__work-item-text {
    color: #333b4c;
    font-size: 18px;
    @include regular();
    text-align: center;
    max-width: 238px;
    width: 100%;
  }

  &__options {
  }

  &__options-flex {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
  }

  &__options-item {
    box-shadow: 0 12px 34px rgba(65, 62, 101, 0.102519);
    border-radius: 10px;
    width: 100%;
    padding: 45px 30px;
    box-sizing: border-box;
    margin-bottom: 30px;

    @for $i from 1 through 2 {
      &:nth-child(#{$i}) {
        background: url('../../../../assets/images/about/about-icon-#{$i}.svg') top right no-repeat,
          #ffffff;
      }
    }

    @media screen and (max-width: 800px) {
      width: 100%;
      margin-bottom: 30px;
    }
  }

  &__options-item-heading {
    font-size: 28px;
    margin-bottom: 15px;
    @include bold();
    color: #333b4c;
  }

  &__options-item-text {
    margin-bottom: 15px;
  }

  &__options-item-list {
    padding-left: 0;
    list-style: none;

    li {
      margin-bottom: 10px;

      &:before {
        content: url('../../../../assets/images/star.svg');
        margin-right: 12px;
      }
    }

    a {
      color: #2656F9;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .about {

    &__top {
      justify-content: space-between;
    }

    &__top-content {
      max-width: 400px;
      margin-right: 40px;
    }
  }
}

@media screen and (max-width: 880px) {
  .about {

    &__std-title {
      font-size: 28px;
    }

    &__top {
      display: block;
    }

    &__top-content {
      max-width: 100%;
      margin-right: 0;
    }

    &__top-img-wrap {
      min-width: 227px;
      width: 227px;
      height: 243px;
      margin-top: 0;
    }

    &__work-list {
      flex-direction: column;
      align-items: center;
    }

    &__work-item {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }


    &__work-item-icon-wrap {

      &:after {
        display: none;
      }
    }

    &__options-item {
      padding: 20px 10px;
    }

    &__options-item-heading {
      font-size: 24px;
    }
  }
}
