@import 'assets/style/_mixins.scss';

.work-scheme-block {
  flex: 1 0 182px;
  //margin: 0 10px 50px;
  padding: 0 10px;
  margin-bottom: 50px;
  position: relative;
  &__arrow {
    z-index: 1;
    position: absolute;
    top: calc(64px / 2);
    width: calc(100% - 64px - 20px - 10px);
    left: calc(50% + (64px / 2) + 10px);
    background-color: #dbe6f2;
    height: 2px;
    .work-scheme-block:last-child & {
      display: none;
    }
  }
  &__arrow-icon {
    width: 10px;
    height: 13px;
    position: absolute;
    top: -6px;
    left: 100%;
  }
  &__content {
    position: relative;
    z-index: 2;
  }
  &__icon-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-color: #2058BA;
    margin: 0 auto 12px;
  }
  &__title {
    max-width: 182px;
    margin: 0 auto;
    @include regular();
    font-size: 16px;
    line-height: 22px;
    text-align: center;

    a {
      color: $main-blue;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

@media screen and (max-width: 980px) {
  .work-scheme-block {
    &__arrow {
      display: none;
    }
  }
}

@media screen and (max-width: 480px) {
  .work-scheme-block {
    flex: 0 1 auto;
    margin-bottom: 100px;
    &:last-child {
      margin-bottom: 0;
    }
    &__arrow {
      display: block;
      top: calc(100% + 10px);
      width: 2px;
      left: 50%;
      height: 80px;
    }
    &__arrow-icon {
      top: calc(100% - 10px);
      left: -4px;
      transform: rotateZ(90deg);
    }
  }
}
