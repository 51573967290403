$main-blue: #0655A4;
$hover-blue: #266BB0;
$main-black: #333B4C;
$orange: #FB8C00;
$hover-orange: #FB9B21;
$dark-blue: #04407C;
$grey: #7D7D7D;
$brightBlue: #2656F9;
$black: #000717;
$yellow: #efc885;
$grey: #7d7284;
$headerHeight: 167px;
