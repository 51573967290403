@import 'assets/style/_mixins.scss';

.profile-dropdown {
  background: #F9F9F9;
  border-radius: 16px;
  width: 360px;
  position: absolute;
  right: 0;
  top: 84px;

  &__item {
    height: 57px;
    padding-left: 32px;
    padding-right: 32px;
    display: flex;
    align-items: center;

    &:first-child {
      border-bottom: 1px solid rgba(51, 59, 76, 0.1);
    }
  }

  &__name {
    color: #333B4C;
    font-size: 16px;
    @include helvetica();
    text-decoration: none;
  }

  &__logout {
    @include reset-button-styles();
    display: flex;
  }

  &__logout-text {
    color: #727D84;
    font-size: 16px;
    margin-right: 11px;
    @include helvetica();
  }

  &__logout-icon {
    width: 18px;
    height: 18px;
  }
}

@media screen and (max-width: 400px) {
  .profile-dropdown {
    background: #F9F9F9;
    border-radius: 0;
    width: 100%;
    position: absolute;
    right: 0;
    left: 0;
    height: 100vh;
    top: 104px;
  }
}
