@import 'assets/style/_mixins.scss';

.how-it-works {
  padding-top: 50px;
  &__title {
    @include std-title();
    margin-bottom: 42px;
  }
}

@media screen and (max-width: 880px) {
  .how-it-works {
    &__title {
      margin-bottom: 30px;
    }
  }
}

@media screen and (max-width: 580px) {
  .how-it-works {
    padding-top: 30px;
  }
}