.circle-loader {
  position: relative;

  &__main {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border-style: solid;
  }
  &__spinning {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border-style: solid;

    border-image: initial;
    animation: animation 1s linear infinite forwards running;
  }
}

@keyframes animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
