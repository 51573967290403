@import 'assets/style/_mixins.scss';

.logout-popup {

  &__text {
    text-align: center;
    @include regular();
    font-size: 18px;
    margin-bottom: 40px;
  }

  &__button {
    width: 190px;
    margin-left: auto;
    margin-right: auto;
    height: 50px;
    font-size: 14px;
  }
}
