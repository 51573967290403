@import 'assets/style/_mixins.scss';
@import 'assets/style/_variables.scss';

.managers-list {
  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
  }
  &__msg {
    @include regular();
    font-size: 18px;
    line-height: 1.3;
    color: $black;
    margin-bottom: 35px;
    &_fail {}
    &_empty {}
  }
  &__item {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}