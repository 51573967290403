@import 'assets/style/_mixins.scss';

.strategy-desc {
  background: #f1f1f1;
  padding: 48px 28px 48px 44px;
  border-radius: 30px;
  min-height: 280px;

  &__title {
    @include medium();
    font-size: 32px;
    margin-bottom: 16px;
    color: $black;
  }

  &__main {
    position: relative;
  }

  &__quote {
    position: absolute;
    top: -72px;
    left: 20px;
    width: 48px;
    height: 40px;

    path {
      fill: #dddee0;
    }
  }

  &__desc {
    font-size: 16px;
    @include regular();
    line-height: 1.6;

    .single__author-comment & {
      max-width: 60%;
      width: 100%;
    }
  }
}

@media screen and (max-width: 800px) {
  .strategy-desc {
    background: transparent;
    padding: 0 20px;
    border-radius: 0;
    min-height: auto;

    &__desc {
      .single__author-comment & {
        max-width: 100%;
      }
    }
  }
}
