@import 'assets/style/_mixins.scss';

.partner-page {
  padding-top: 26px;
  padding-bottom: 140px;

  &__container {
  }

  &__bread-crumbs {
    margin-bottom: 26px;
  }

  &__main {
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  &__info {
    background: #f9f9f9;
    box-shadow: 0 12px 34px rgba(65, 62, 101, 0.102519);
    border-radius: 20px;
    margin-bottom: 56px;
    padding: 40px;
    display: flex;
    flex-direction: row;
  }

  &__info-col {
    &_info {
      width: 53%;
    }
    &_img {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 60px;
      width: 47%;
    }
  }

  &__title {
    @include std-title();
    margin-bottom: 16px;
  }

  &__desc {
  }

  &__desc-item {
    font-size: 16px;
    @include regular();
    line-height: 1.3;
    margin-bottom: 10px;
    color: $black;

    &:last-child {
      margin-bottom: 0;
    }
  }
  &__img {
    vertical-align: middle;
    max-width: 100%;
  }
}

@media screen and (max-width: 880px) {
  .partner-page {
    padding-bottom: 80px;
    &__info {
      flex-direction: column-reverse;
    }
    &__info-col {
      &_info {
        width: 100%;
      }
      &_img {
        padding-left: 0;
        width: 100%;
        margin-bottom: 40px;
      }
    }
  }
}

@media screen and (max-width: 680px) {
  .partner-page {
    padding-bottom: 60px;

    &__info {
      padding: 20px;
    }
  }
}

@media screen and (max-width: 580px) {
  .partner-page {
    &__info {
      padding: 30px 20px;
    }
    &__info-col {
      &_info {
        width: 100%;
      }
      &_img {
        padding-left: 0;
        width: 100%;
        margin-bottom: 40px;
      }
    }
  }
}
