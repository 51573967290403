@import "normalize";
@import "fonts";
@import "variables";

body {
  font-size: 18px;
  font-family: HelveticaRegular, sans-serif;
  color: $main-black;
  line-height: 1.5;

  * {
    box-sizing: border-box;
  }
}

.App {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  flex-grow: 2000;
  justify-content: space-between;
}

.container {
  max-width: 1242px + 80px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0 40px;


  @media screen and (max-width: 1000px) {
    padding: 0 27px;
  }

  @media screen and (max-width: 480px) {
    padding: 0 12px;
  }
}

.h2 {
  font-family: 'PT Sans', 'Roboto', sans-serif;
  font-size: 45px;
  font-weight: bold;
  line-height: 1.11;
  margin-bottom: 30px;

  @media screen and (max-width: 600px) {
    font-size: 24px;
  }
}

.h3 {
  font-size: 28px;
  font-weight: bold;
  line-height: 1;
  font-family: 'PT Sans', 'Roboto', sans-serif;
  margin-bottom: 15px;

  @media screen and (max-width: 600px) {
    font-size: 22px;
  }
}
