@import 'assets/style/_mixins.scss';

.fail-message {
  background: white;
  box-shadow: 0 12px 34px rgba(65, 62, 101, 0.102519);
  border-radius: 10px;
  width: 100%;
  padding: 50px;
  box-sizing: border-box;

  &__text {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    font-family: 'PT Sans', 'Roboto', sans-serif;
    font-size: 18px;
    //line-height: 1.2;
    text-align: center;

    a {
      color: $main-blue;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
