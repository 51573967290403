@import 'assets/style/_mixins.scss';

.upload-files {
  &__input-wrap {
    border: 1px solid rgba(0, 7, 23, .1);
    border-radius: 20px;
    padding: 25px 20px 13px;
    display: flex;
    flex-direction: column;
    align-items: center;
    outline: none;
  }
  &__icon {
    margin-bottom: 10px;
  }
  &__text {
    text-align: center;
    color: rgba(0, 7, 23, .3);
  }
  &__list-wrap {
    margin: 8px 0;
  }
  &__list {}
  &__list-item {
    @include regular();
    font-size: 16px;
    line-height: 1.3;
    color: #000717;
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}