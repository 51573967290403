@import 'assets/style/_mixins.scss';

.text-block {
  box-shadow: 0 12px 34px rgba(65, 62, 101, 0.102519);
  border-radius: 30px;
  padding: 40px;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  margin-bottom: 50px;
  &_flipped {
    flex-direction: row-reverse;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &__col {
    &_text {
      width: 55%;
      display: flex;
      flex-direction: column;
    }
    &_img {
      width: 45%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 40px;

      .text-block_flipped & {
        padding-left: 0;
        padding-right: 40px;
      }
    }
  }
  &__title {
    @include std-title();
    margin-bottom: 24px;
  }
  &__wrap {
    flex-grow: 20;
    display: flex;
    flex-direction: column;
  }
  &__body {
    @include regular();
    font-size: 16px;
    line-height: 1.3;
    flex-grow: 20;
  }
  &__footer {
    @include regular();
    font-size: 14px;
    line-height: 1.3;
    text-align: right;
    margin-top: 30px;
  }
  &__img {
    vertical-align: top;
    max-width: 100%;
  }
}

@media screen and (max-width: 880px) {
  .text-block {
    flex-direction: column-reverse;
    &__col {
      &_text {
        width: 100%;
      }
      &_img {
        width: 100%;
        padding-left: 0;
        margin-bottom: 40px;

        .text-block_flipped & {
          padding-right: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 580px) {
  .text-block {
    padding: 30px 20px;
    margin-bottom: 30px;
  }
}