@import 'assets/style/_mixins.scss';

.go-to-strategies {
  &_footer {
    padding-top: 80px;
    text-align: center;
  }
  &__btn {
    display: inline-flex;
    padding: 0 40px;
    font-size: 16px;
  }
}
