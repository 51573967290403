@import 'assets/style/_mixins.scss';
@import 'assets/style/_variables.scss';

.standard-button {
  @include reset-button-styles();
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  height: 50px;
  transition: all 0.3s ease;
  border-radius: 32px;
  cursor: pointer;
  transition-duration: .3s;
  @include bold();
  font-size: 14px;
  color: white;

  &:disabled {
    cursor: default;
  }

  &_blue {
    background: $brightBlue;

    &:disabled {
      background: $grey;
    }

    &:hover:not(:disabled) {
      background: $hover-blue;
    }
  }

  &_blue-border {
    border: 2px solid $brightBlue;
    color: $brightBlue;

    &:disabled {
      background: white;
      border-color: #9E9E9E;
      color: #9E9E9E;
    }

    &:hover:not(:disabled) {
      border: 1px solid $brightBlue;
      color: $brightBlue;
    }
  }

  &_orange {
    background: $orange;

    &:hover:not(:disabled) {
      background: $hover-orange;
    }
  }

  &_orange-border {
    border: 1px solid $orange;
    color: $orange;

    &:hover:not(:disabled) {
      background: $orange;
      border-color: $orange;
      color: white;
    }
  }

  &_grey-border {
    border: 1px solid #444444;
    color: #444444;

    &:disabled {
      background: rgba(68, 68, 68, 0.51);
      border-color: rgba(68, 68, 68, 0.51);
      color: rgba(255, 255, 255, 0.55);
    }

    &:hover:not(:disabled) {
      border: 1px solid $main-blue;
      color: $main-blue;
    }
  }

  &_white {
    background: white;
    color: $brightBlue;

    &:disabled {
      background: rgba(255, 255, 255, 0.5);
      color: rgba(255, 255, 255, 0.7);
    }

    &:hover:not(:disabled) {
      background: rgba(255, 255, 255, 0.1);
    }
  }

  &_white-border {
    border: 1px solid white;
    background: transparent;
    color: white;

    &:disabled {
      background: rgba(255, 255, 255, 0.5);
      color: rgba(255, 255, 255, 0.7);
    }

    &:hover:not(:disabled) {
      background: white;
      color: $brightBlue;
    }
  }

  &_yellow {
    background: #EFC885;
    color: #000717;

    &:disabled {
      background: $grey;
      opacity: 0.6;
      color: rgba(255, 255, 255, 0.7);
    }

    &:hover:not(:disabled) {
      background: $orange;
    }
  }

  &_yellow-border {
    border: 1px solid #EFC885;
    background: transparent;
    color: #000717;

    &:disabled {
      background: $grey;
      color: rgba(255, 255, 255, 0.7);
    }

    &:hover:not(:disabled) {
      background: white;
      color: #EFC885;
    }
  }
}
