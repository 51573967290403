@import 'assets/style/_mixins.scss';

.footer {
  background: #494F5D;
  padding: 50px 0;

  &__flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 33px;
  }

  &__nav {

  }

  &__nav-list {
    margin-left: 0;
    padding-left: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
  }

  &__nav-list-item {
    margin-right: 57px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__nav-list-item-link {
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    @include helvetica();
  }

  &__left {

  }

  &__logo {
    width: 139px;
    height: 114px;
    display: block;
  }

  &__logo-img {
    Width: 100%;
    height: 100%;

    path {
      fill: white;
    }
  }

  &__right {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__disclaimer {

  }

  &__paragraph {
    @include helvetica();
    margin-bottom: 11px;
    font-size: 12px;
    line-height: 18px;
    color: rgba(248, 248, 248, 0.5);

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__paragraph-link {
    @include helvetica();
    margin-bottom: 11px;
    font-size: 12px;
    line-height: 18px;
    color: #a3b3ff;

    &:hover {
      text-decoration: none;
    }
  }

  &__bottom {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__bottom-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
  }

  &__phone {
    font-size: 16px;
    @include helvetica();
    color: white;
    margin-right: 52px;
    text-decoration: none;
    line-height: 1;

    a {
      color: white;
      text-decoration: none;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &__email {
    font-size: 16px;
    @include helvetica();
    color: white;
    margin-right: 52px;
    text-decoration: none;
    text-transform: uppercase;
    line-height: 1;

    a {
      color: white;
      text-decoration: none;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &__social {

  }

  &__social-item {
    color: #ffffff;
    text-decoration: none;
    margin-right: 52px;
    font-size: 16px;
    @include helvetica();
    line-height: 1;

    a {
      color: white;
      text-decoration: none;
    }

    &:hover {
      text-decoration: underline;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__copyright {
    font-size: 12px;
    @include helvetica();
    color: white;
    line-height: 1;
  }
}

@media screen and (max-width: 1200px) {
  .footer {

    &__flex {
      margin-bottom: 20px;
    }

    &__nav-list-item {
      margin-left: 0;
      margin-right: 30px;
      margin-bottom: 30px;
    }
  }
}

@media screen and (max-width: 1050px) {
  .footer {

    &__bottom {
      flex-direction: column-reverse;
      justify-content: flex-start;
      align-items: flex-start;
    }

    &__bottom-right {
      margin-bottom: 20px;
      align-items: center;
      justify-content: flex-start;
    }
  }
}

@media screen and (max-width: 800px) {
  .footer {

    &__flex {
      flex-direction: column;
      align-items: flex-start;
    }

    &__left {
      margin-bottom: 50px;
    }

    &__copyright {
      font-size: 12px;
    }
  }
}

@media screen and (max-width: 680px) {
  .footer {

    &__email {
      margin-bottom: 10px;
    }

    &__phone {
      margin-bottom: 10px;
    }

    &__social-item {
      margin-bottom: 10px;
    }
  }
}
