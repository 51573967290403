@import 'assets/style/_mixins.scss';

.how-it-works-info {
  display: flex;
  flex-direction: row;
  padding: 40px;
  border-radius: 32px;
  background: url('../../../../assets/images/how-it-works-bg.jpg') no-repeat center center;
  background-size: cover;
  margin-bottom: 64px;
  &__col {
    &_text {
      width: 65%;
      padding-right: 30px;
    }
    &_img {
      width: 35%;
      position: relative;
    }
  }
  &__body {}
  &__text {
    @include regular();
    font-size: 16px;
    line-height: 1.5;
    color: white;
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__img {
    position: absolute;
    top: -80px;
    left: calc(50%);
    transform: translateX(-50%);
    vertical-align: middle;
    max-width: 100%;
  }
}

@media screen and (max-width: 1050px) {
  .how-it-works-info {
    &__img {
      position: relative;
      top: auto;
      left: auto;
      transform: translateX(0);
    }
  }
}

@media screen and (max-width: 880px) {
  .how-it-works-info {
    flex-direction: column-reverse;
    margin-bottom: 40px;
    &__col {
      &_text {
        width: 100%;
        padding-right: 0;
      }
      &_img {
        text-align: center;
        width: 100%;
        margin-bottom: 40px;
      }
    }
  }
}

@media screen and (max-width: 880px) {
  .how-it-works-info {
    padding: 30px 20px;
  }
}