@import 'assets/style/_mixins.scss';

.lk-strategy-item {
  background: #ffffff;
  box-shadow: 0px 12px 34px rgba(65, 62, 101, 0.102519);
  border-radius: 10px;
  padding: 40px 35px;

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  &__number {
    color: #7d7d7d;
    @include helvetica();
    font-size: 14px;
  }

  &__desc-block {
    margin-bottom: 28px;
  }

  &__title {
    color: #333b4c;
    @include bold();
    margin-bottom: 20px;
    font-size: 28px;
  }

  &__desc {
    color: #333b4c;
    font-size: 18px;
    line-height: 27px;
    @include helvetica();
  }

  &__main {
    display: flex;
    align-items: flex-start;
  }

  &__features-block {
    width: calc((100% - 70px) / 2);
    margin-right: 70px;
  }

  &__features-list {
    margin-bottom: 20px;
  }

  &__features-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__features-title {
    color: #333b4c;
    font-size: 24px;
    @include bold();
  }

  &__features-change {
    width: 200px;
    height: 40px;
    border-radius: 8px;
    font-size: 15px;
  }

  &__features-item {
    color: #333b4c;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    .strategy-characteristic-item__title {
      color: #333b4c;
    }

    .strategy-characteristic-item__value {
      color: #444444;
    }
  }

  &__button-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__chart {
    width: calc((100% - 70px) / 2);
  }
}

@media screen and (max-width: 1100px) {
  .lk-strategy-item {

    &__main {
      display: block;
    }

    &__features-block {
      width: 100%;
      margin-right: 0;
      margin-bottom: 40px;
    }

    &__chart {
      width: 100%;
    }
  }
}

@media screen and (max-width: 680px) {
  .lk-strategy-item {
    padding: 40px 10px;

    &__features-top {
      display: block;
    }

    &__features-title {
      margin-bottom: 10px;
    }

    &__features-item {
      .strategy-characteristic-item__title {
        @include medium();
      }
    }
  }
}
