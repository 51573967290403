@import 'assets/style/_mixins.scss';

.statistic-table {
  background: #FFFFFF;
  box-shadow: 0px 12px 34px rgba(65, 62, 101, 0.102519);
  border-radius: 10px;
  padding: 40px 35px;

  &__head {
    display: flex;
    align-items: center;
  }

  &__body {

  }

  &__line {
    display: flex;
    align-items: center;

    &:nth-child(odd) {
      background: #EBFCEC;
    }
  }

  &__col {
    min-height: 52px;
    padding-left: 30px;
    border-left: 1px solid #E6E6E9;
    width: 50%;
    display: flex;
    align-items: center;

    &:first-child {
      border: none;
    }
  }

  &__col-icon {
    margin-right: 9px;
  }

  &__col-text {
    color: #333333;
    font-size: 16px;
    @include regular();

    .statistic-table__head & {
      @include bold();
    }
  }
}

@media screen and (max-width: 600px) {
  .statistic-table {
    padding: 20px 15px;

    &__col {
      padding-left: 10px;
    }
  }
}
