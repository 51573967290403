@import 'assets/style/_mixins.scss';

.work-flow {
  padding-top: 23px;
  padding-bottom: 100px;
  background: darken(#F8F8F8, 3%);
  overflow: hidden;

  &__container {
    margin-bottom: 100px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  &__block {
    width: calc((100% - 135px) / 2);
    margin-right: 135px;
    display: flex;
    align-items: center;

    &:first-child {
      width: 100%;
      margin-right: 0;
      margin-bottom: 92px;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__block-illustration-wrap {

  }

  &__block-illustration {
    width: 391px;
    min-width: 391px;
  }

  &__block-content {
    max-width: 561px;
    width: 100%;
    margin-right: 107px;

    &:only-child {
      max-width: 100%;
      margin-right: 0;
    }
  }

  &__block-title {
    font-size: 30px;
    @include bold();
    color: $black;
    margin-bottom: 25px;
  }

  &__block-desc-wrap {
    margin-bottom: 40px;
  }

  &__block-desc {
    font-size: 16px;
    @include regular();
    line-height: 1.5;
    color: $black;

    .work-flow__block-desc-wrap & {
      margin-bottom: 22px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      font-size: 16px;
      @include regular();
      line-height: 1.5;
      color: $black;
      &:hover {
        text-decoration: none;
      }
    }

    button {
      @include reset-button-styles();
      font-size: 16px;
      @include regular();
      line-height: 1.5;
      color: $black;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }

  &__block-desc-item {
    display: flex;
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__block-desc-item-number {
    font-size: 16px;
    @include regular();
    color: $black;
    margin-right: 20px;
  }

  &__block-button {
    width: 200px;

    .work-flow__block:last-child & {
      width: 250px;
    }
  }

}

@media screen and (max-width: 980px) {
  .work-flow {
    &__block {
      width: 100%;
      margin-right: 0;
      margin-bottom: 50px;

      &:first-child {
        margin-bottom: 50px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__block-title {
      font-size: 24px;
    }
  }
}
