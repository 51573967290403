@import 'assets/style/_mixins.scss';

.faq-item {
  width: 100%;
  border-bottom: 1px solid rgba(51, 59, 76, 0.1);
  position: relative;

  &_open {
    padding-bottom: 32px;
  }

  &:first-child {
    padding-top: 0;
  }

  &__summary {
    @include reset-button-styles();
    width: 100%;
    position: relative;
    padding: 14px 30px 14px 0;
    text-align: left;

    &::-webkit-details-marker {
      display: none;
    }
  }

  &__summary-icon {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 0;
    top: 12px;
    path {
      stroke: #000717;
    }
  }

  &__summary-text {
    position: relative;
    z-index: 2;
    width: 100%;
    color: #000717;
    font-size: 18px;
    @include regular();
    transition: color 0.3s ease-in-out;
  }

  &__body {
    padding-top: 18px;
    max-width: 600px;
    color: rgba(0, 7, 23, 0.8);
    @include regular();
    font-size: 16px;
    //line-height: 1.3;

    b {
      @include bold();
    }
    a {
      color: $hover-blue;
      &:hover {
        text-decoration: none;
      }
    }

    ol, ul {
      margin-top: 10px;
      margin-bottom: 25px;
      padding-left: 0;
      list-style: none
    }

    li {
      padding-left: 22px;
      position: relative;
    }

    ul>li::before {
      position: absolute;
      top: -1px;
      bottom: 0;
      left: 0;
      content: '—';
      font-size: 16px;
    }

    ol {
      counter-reset: list;
    }

    ol>li::before {
      position: absolute;
      top: 3px;
      left: 0;
      counter-increment: list;
      content: counter(list);
      font-size: 14px;
    }

  }
}