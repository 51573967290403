@import 'assets/style/_mixins.scss';
@import 'assets/style/_variables.scss';

.strategies-item {
  border-radius: 24px;
  background: darken(#fafafa, 2%);
  padding: 42px 40px 32px 40px;
  min-height: 480px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: all 0.3s ease-in-out;

  &:hover {
    box-shadow: 0 20px 98px rgba(0, 34, 2, 0.15);
  }

  &__title {
    color: $black;
    font-size: 30px;
    @include bold();
    line-height: 1;
    margin-bottom: 6px;
    text-transform: uppercase;
    text-decoration: none;
  }

  &__risk {
    font-size: 14px;
    @include regular();
    color: $grey;
    margin-bottom: 30px;
  }

  &__purpose-block {
    background: $yellow;
    border-radius: 16px;
    padding: 16px 24px 22px 24px;
    margin-bottom: 34px;
  }

  &__purpose-title {
    font-size: 14px;
    color: $black;
    @include regular();
    margin-bottom: 8px;
  }

  &__purpose-itself {
    font-size: 14px;
    @include medium();
    max-width: 85%;
    width: 100%;
    color: $black;
  }

  &__main {
    display: flex;
    width: 100%;
    margin-bottom: 50px;
  }

  &__features {
    width: 50%;
    min-width: 50%;
    padding-right: 10px;
  }

  &__features-item {
    margin-bottom: 14px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__features-title {
    font-size: 18px;
    color: $black;
    @include bold();
    margin-bottom: 4px;
  }

  &__features-desc {
    font-size: 14px;
    @include regular();
    color: $grey;
  }

  &__chart-block {
    width: 50%;
    min-width: 50%;
  }

  &__chart-top {
    margin-bottom: 16px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__subscribers {
    font-size: 14px;
    @include regular();
    color: $grey;
  }

  &__hint-button {
    @include reset-button-styles();
  }

  &__hint-button-icon {
    width: 20px;
    height: 20px;
  }

  &__hint {
    background: white;
    box-shadow: 0 12px 34px rgba(65, 62, 101, 0.102519);
    border-radius: 5px;
    position: absolute;
    right: -5px;
    top: 26px;
    width: 169px;
    padding: 12px;
    z-index: 30;
    @include regular();
    font-size: 10px;
    color: $grey;

    &:before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid white;
      top: -10px;
      right: 5px;
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__author {
    display: flex;
    align-items: center;
    position: relative;
    width: calc(100% - 200px);
  }

  &__author-photo {
    width: 44px;
    height: 44px;
    min-width: 44px;
    border-radius: 50%;
    display: block;
    margin-right: 16px;
    object-fit: cover;
  }

  &__author-text {
    width: calc(100% - 44px - 16px);
    padding-right: 20px;
  }

  &__author-name {
    font-size: 16px;
    color: #000717;
    @include regular();
    line-height: 1;
  }

  &__author-info {
    font-size: 14px;
    @include regular();
    line-height: 1;
    color: $grey;
  }

  &__link {
    width: 200px;
    color: $black;
  }
}

@media screen and (max-width: 1100px) {
  .strategies-item {

    &__title {
      font-size: 24px;
      word-break: break-all;
    }
  }
}

@media screen and (max-width: 600px) {
  .strategies-item {
    padding: 25px 16px;

    &__main {
      display: block;
    }

    &__features {
      width: 100%;
      min-width: 100%;
      margin-bottom: 20px;
    }

    &__chart-block {
      width: 100%;
      min-width: 100%;
    }

    &__bottom {
      display: block;
    }

    &__author {
      width: 100%;
      margin-bottom: 20px;
    }

    &__link {
      width: 100%;
    }
  }
}
