@import 'assets/style/_mixins.scss';

.lk {
  &__loader {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__footer {
    padding: 80px 0 25px;
  }
  &__footer-text {
    @include helvetica();
    font-size: 12px;
    line-height: 18px;
    color: #727d84;
  }
}

@media screen and (max-width: 1000px) {
  .lk {
    &__footer {
      padding-top: 50px;
    }
  }
}
