@import 'assets/style/_mixins.scss';

.chart {

  &__inner {
    position: relative;

    svg {
      overflow: visible;
    }
  }

  &__error-message {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    @include regular();
    font-size: 16px;
    height: 200px;
    background: rgba(38, 86, 249, 0.6);
    border-radius: 20px;
    padding: 20px;
    color: white;
  }

  &__loader {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__hint {
    background: white;
    box-shadow: 0 16.8956px 82.7884px rgba(0, 34, 2, 0.08);
    border-radius: 4px;
    padding: 12px 14px;
    min-width: 136px + 50px;
    margin-right: 20px;
  }

  &__hint-date {
    color: #CCCCCC;
    @include regular();
    font-size: 12px;
    margin-bottom: 3px;
  }

  &__hint-profit-wrap {
  }

  &__hint-profit-title {
    @include regular();
    font-size: 12px;
    margin-right: 5px;
    color: #333b4c;
  }

  &__hint-profit {
    @include regular();
    font-size: 12px;

    &_positive {
      color: #1aa720;
    }

    &_negative {
      color: #8e3838;
    }
  }

  .rv-crosshair__line {
    background: $yellow
  }
}
