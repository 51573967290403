@import 'assets/style/_mixins.scss';

.strategy-characteristics {
  background: #2058BA;
  border-radius: 30px;
  padding: 48px 60px 116px 60px;

  &__title {
    font-size: 32px;
    color: white;
    @include medium();
    margin-bottom: 31px;
  }

  &__list {

  }

  &__item {
    border-bottom: 1px solid rgba(237, 237, 237, 0.1);
    padding-bottom: 18px;
    padding-top: 15px;

    &:last-child {
      border-bottom: none;
    }
  }
}

@media screen and (max-width: 1200px) {
  .strategy-characteristics {
    padding: 20px;

    &__title {
      font-size: 30px;
    }
  }
}

@media screen and (max-width: 800px) {
  .strategy-characteristics {
    border-radius: 0;
  }
}
