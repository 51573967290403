@import 'assets/style/_mixins.scss';
@import 'assets/style/_variables.scss';

.single {
  padding-top: 22px;

  &__bread-crumbs {
    margin-bottom: 40px;
  }

  &__title {
    color: #000717;
    font-size: 45px;
    @include bold();
    margin-bottom: 22px;
    text-transform: uppercase;
  }

  &__top {
    display: flex;
    margin-bottom: 85px;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__top-text {
    width: calc((100% - 80px) / 2);
    margin-right: 80px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__top-author-loader {
    height: 100px;
  }

  &__top-author {
    display: flex;
    align-items: center;
    margin-bottom: 17px;
  }

  &__top-author-photo {
    width: 80px;
    min-width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: 25px;
    object-fit: cover;
  }

  &__top-author-info {
    //width: calc((100% - 80px) / 2 - 50px);
    //padding-top: 14px;
  }

  &__top-author-name {
    color: #000717;
    font-size: 24px;
    @include medium();
    margin-bottom: 11px;
    line-height: 1;
  }

  &__invest-type {
    background: #F6F6F6;
    padding: 6px 16px;
    display: flex;
    align-items: center;
    margin-bottom: 22px;
    border-radius: 8px;
  }

  &__invest-type-text {
    @include medium();
    color: #727D84;
    font-size: 14px;
  }

  //&__invest-type-question {
  //  width: 20px;
  //  height: 20px;
  //  border-radius: 50%;
  //  @include medium();
  //  color: #727D84;
  //  font-size: 14px;
  //  background: #EAEAEB;
  //  text-align: center;
  //}

  &__top-author-desc {
    font-size: 16px;
    color: #727d84;
    line-height: 1.3;
    @include regular();
  }

  &__top-description {
    margin-bottom: 40px;
    @include regular();
    font-size: 16px;
    line-height: 1.5;
    color: $black;
  }

  &__goal {}
  &__goal-title {
    @include bold();
    margin-bottom: 5px;
  }

  &__buttons-list {
    width: 100%;
  }

  &__button {
    max-width: 260px;
    width: 100%;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    &:only-child {
      margin-bottom: 0;
    }
  }

  &__graphs {
    width: calc((100% - 80px) / 2 + 50px);
    margin-top: 29px;
    background: #FFFFFF;
    box-shadow: 0 12px 34px rgba(65, 62, 101, 0.102519);
    border-radius: 30px;
    padding: 25px 65px;
  }

  &__graphs-title {
    color: #333B4C;
    font-size: 18px;
    @include medium();
    margin-bottom: 14px;
  }

  &__profit {
    margin-bottom: 27px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
  }

  &__profit-nav {
    margin-bottom: 17px;
  }

  &__profit-loader {
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__profit-value {
    font-size: 32px;
    @include medium();
    color: $brightBlue;
    line-height: 1;
    margin-right: 5px;

    &_negative {
      color: #8e3838;
    }
  }

  &__profit-text {
    @include regular();
    color: #727D84;
    font-size: 12px;
    max-width: 196px;
  }

  &__specifications {
    padding-bottom: 30px;
  }

  &__specifications-container {
    display: flex;
  }

  &__specifications-column {
    &:first-child {
      width: calc(100% - 394px - 30px);
      margin-right: 30px;
    }

    &:nth-child(2) {
      width: 394px;
      min-width: 394px;
    }

    &:only-child {
      width: 100%;
      margin-right: 0;
    }
  }

  &__specifications-list {
    &_non-logged-in {
      display: flex;
    }
  }

  &__specifications-item {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    .single__specifications-list_non-logged-in & {
      margin-bottom: 5px;
      margin-right: 5px;

      &:after {
        content: ',';
        display: inline-block;
      }

      &:last-child {
        margin-right: 0;
        &:after {
          display: none;
        }
      }
    }

    .portfolio-content & {
      color: white;
    }
  }

  &__deals-wrap {
    margin-bottom: 29px;
  }

  &__deals {
    background: #1c3462;
    border-radius: 30px;
    padding: 46px 82px 64px 59px;
    display: flex;

  }

  &__trades {
    //margin-right: 153px;
    //width: calc(100% - 300px - 153px);
    width: 100%;
    position: relative;

    //&:after {
    //  content: '';
    //  position: absolute;
    //  width: 1px;
    //  background: #ffffff;
    //  opacity: 0.06;
    //  top: 0;
    //  bottom: 0;
    //  right: -76px;
    //}
  }

  &__comments-wrap {
    margin-bottom: 100px;
  }

  &__portfolio-list {
    width: 300px;
    min-width: 300px;
  }

  &__author-info {
    margin-bottom: 30px;
  }
  &__activate-btn-wrap {
    margin: 60px 0;
    .single__button {
      margin: 0 auto;
    }
  }
  &__user-form-wrap {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 1100px) {
  .single {
    &__specifications-container {
      display: block;
    }

    &__specifications-column {
      &:first-child {
        width: 100%;
        margin-right: 0;
        margin-bottom: 30px;
      }

      &:nth-child(2) {
        width: 100%;
        min-width: 100%;
      }
    }

    &__graphs {
      padding: 25px 20px;
    }

    &__deals {
      display: block;
    }

    &__trades {
      margin-right: 0;
      width: 100%;
      margin-bottom: 30px;

      &:after {
        display: none;
      }
    }

    &__portfolio-list {
      width: 100%;
      min-width: 100%;
    }
  }
}

@media screen and (max-width: 1000px) {
  .single {
    &__top {
      flex-direction: column-reverse;
    }

    &__top-text {
      width: 100%;
    }

    &__graphs {
      width: 100%;
      min-width: auto;
      margin-bottom: 50px;
    }

    &__profit {
      margin-bottom: 30px;
    }
  }
}

@media screen and (max-width: 800px) {
  .single {

    &__title {
      font-size: 32px;
      margin-bottom: 20px;
    }

    &__author {
      padding: 20px 0 50px;
    }

    &__author-photo {
      width: 160px;
      min-width: 160px;
      height: 160px;
      margin-right: 0;
    }

    &__author-text {
      width: 100%;
      margin-top: 25px;
    }

    &__graphs {
      padding: 0;
      width: calc(100% + 40px);
      margin: 0 -20px;
      border-radius: 0;
      box-shadow: none;
    }

    &__graphs-title {
      padding-left: 20px;
    }

    &__deals {
      margin-left: -27px;
      margin-right: -27px;
      width: calc(100% + 54px);
      border-radius: 0;
      padding-left: 20px;
      padding-right: 20px;
    }

    &__specifications-column {
      &:first-child {
        margin-left: -27px;
        margin-right: -27px;
        width: calc(100% + 54px);
      }
    }
    &__user-form-wrap {
      margin-bottom: 0;
      &.container {
        padding: 0;
      }
    }
    &__user-form {
      border-radius: 0;
    }
  }
}

@media screen and (max-width: 480px) {
  .single {

    &__profit-nav {
      padding-left: 10px;
      padding-right: 10px;
    }

    &__graphs {
      margin-left: -12px;
      margin-right: -12px;
      width: calc(100% + 24px);
    }

    &__deals {
      margin-left: -12px;
      margin-right: -12px;
      width: calc(100% + 24px);
    }

    &__specifications-column {
      &:first-child {
        margin-left: -12px;
        margin-right: -12px;
        width: calc(100% + 24px);
      }
    }
  }
}
