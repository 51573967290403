@import 'assets/style/_mixins.scss';

.lk-main-adviser {
  @include container();

  &__top {
    margin-bottom: 35px;
  }

  &__top-title {
    color: #333B4C;
    font-size: 45px;
    @include bold();
  }

  &__block {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: 500px) {
  .lk-main-adviser {

    &__top-title {
      font-size: 30px;
    }
  }
}
