@import 'assets/style/_mixins.scss';

.top-banner {
  background: $main-blue url('../../../../assets/images/top-bg.jpg') center center;
  background-size: cover;
  margin-top: -$headerHeight;
  padding-top: 195px;
  overflow: hidden;
  padding-bottom: 113px;

  &__container {
    @include container();
    display: flex;
    align-items: flex-start;
  }

  &__content {
    max-width: 561px;
    width: 100%;
  }

  &__title {
    @include std-title();
    color: white;
    line-height: 1.5;
    margin-bottom: 39px;
  }

  &__button-wrap {
    margin-bottom: 83px;
  }

  &__button {
    width: 200px;
    height: 50px;
  }

  &__info {
  }

  &__info-button {
    @include reset-button-styles();
    margin-bottom: 24px;
    font-size: 16px;
    color: white;
    @include regular();
    max-width: 273px;
    text-align: left;
    line-height: 1.6;
  }

  &__info-list {
    display: flex;
  }

  &__info-item {
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__info-item-key {
    font-size: 16px;
    @include regular();
    color: white;
    margin-bottom: 2px;
  }

  &__info-item-desc {
    @include bold();
    color: white;
    font-size: 16px;
  }

  &__image-container {
    width: 563px;
    position: absolute;
    top: 179px;
    left: 52%;
  }

  &__image {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 1280px) {
  .top-banner {
    &__content {
      max-width: 500px;
    }

    &__image-container {
      left: 56%;
      margin-bottom: 60px;
    }
  }
}

@media screen and (max-width: 1180px) {
  .top-banner {
    margin-top: -$headerHeight;
  }
}

@media screen and (max-width: 900px) {
  .top-banner {
    //padding-top: 100px;
    padding-bottom: 50px;

    &_logged-in {
      padding-top: 140px;
    }

    &__container {
      flex-direction: column-reverse;
    }

    &__content {
      position: relative;
      z-index: 30;
      margin-top: -50px;
    }

    &__title {
      margin-bottom: 24px;
    }

    &__button-wrap {
      margin-bottom: 30px;
    }

    &__image-container {
      position: relative;
      top: auto;
      left: auto;
      width: 100%;
      min-width: auto;
      max-width: 500px;
    }
  }
}

@media screen and (max-width: 780px) {
  .top-banner {
    margin-top: -108px;
    padding-top: 140px;
  }
}

@media screen and (max-width: 580px) {
  .top-banner {
    &__image-container {
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 365px) {
  .top-banner {
    &__info-list {
      display: block;
    }

    &__info-item {
      margin-right: 0;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
