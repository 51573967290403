@import 'assets/style/_mixins.scss';

.lk-main {

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
  }

  &__top-title {
    color: #000717;
    @include medium();
    font-size: 24px;
  }

  &__top-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 33px;
  }

  &__top-buttons-item {
    width: 200px;
    min-width: 200px;
    height: 50px;
    font-size: 14px;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__list-item {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__list-item {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: 1000px) {
  .lk-main {
    padding-top: 27px;
    padding-bottom: 40px;

    &__top {
      display: block;
      margin-bottom: 30px;
    }

    &__top-title {
      font-size: 24px;
      margin-bottom: 30px;
    }

    &__top-buttons {
      padding-right: 0;
    }

    &__top-buttons-item {
      width: calc((100% - 10px * 2) / 3);
    }

  }
}

@media screen and (max-width: 720px) {
  .lk-main {

    &__top-buttons {
      display: block;
    }

    &__top-buttons-item {
      width: 100%;
      margin-right: 0;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
