.document-page {
  padding: 100px 0;
  &__content {
    word-break: break-word;
  }
  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
