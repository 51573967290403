@import 'assets/style/_mixins.scss';

.iir-report-popup {
  &__title {
    margin-bottom: 16px;
    color: #333b4c;
    font-size: 45px;
    font-family: 'PT Sans', sans-serif;
    font-weight: bold;
  }

  &__desc {
    margin-bottom: 23px;
    font-family: 'Helvetica', sans-serif;
    font-size: 18px;
    line-height: 27px;
    color: #333b4c;
  }

  &__account-list {
    margin-bottom: 30px;
  }

  &__account-item-wrap {
    margin-bottom: 10px;
    display: flex;
    align-items: flex-start;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__account-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__account-item-checkbox {
    @include reset-button-styles();
    width: 16px;
    height: 16px;
    border: 1px solid #7c7c7c;
    box-sizing: border-box;
    border-radius: 4px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__account-item-checkbox-icon {
    width: 10px;
    height: 10px;
    pointer-events: none;
  }

  &__account-item-checkbox-text {
    font-size: 12px;
    color: #7c7c7c;
  }

  &__nav-wrap {
  }

  &__nav-wrap-title {
    font-size: 14px;
    color: #444444;
    font-family: 'Helvetica', sans-serif;
    margin-bottom: 4px;
  }

  &__nav {
    margin-bottom: 30px;
    display: flex;
  }

  &__buttons-list {
    display: flex;
  }

  &__buttons-item {
    width: calc((100% - 20px) / 2);
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__table-deals-title {
    font-size: 15px;
    @include medium();
    margin-bottom: 10px;
    margin-left: 10px;
  }
}

@media screen and (max-width: 700px) {
  .iir-report-popup {
    &__title {
      font-size: 32px;
    }

    &__buttons-list {
      display: block;
    }

    &__buttons-item {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
