@import 'assets/style/_mixins.scss';

.sms-validations {
  margin-bottom: 20px;
  &__list {
    margin-bottom: 35px;
  }
  &__list-item {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__list-item-icon {
    margin-right: 10px;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
  }
  &__list-item-text {
    padding-top: 3px;
    @include regular();
    font-size: 14px;
    line-height: 1.3;
    color: $black;
  }
  &__msg {
    @include regular();
    font-size: 14px;
    line-height: 1.3;
    color: $grey;
    text-align: center;
  }
}