@import 'assets/style/_mixins.scss';

.managers {
  margin-bottom: 120px;
  &__breadcrumbs {
    margin: 15px 0;
  }
  &__title {
    @include std-title();
    margin-bottom: 30px;
    line-height: 1.3;
  }
  &__btn-wrap {
    text-align: center;
    margin: 50px 0;
  }
  &__btn {
    display: inline-flex;
    padding: 0 40px;
  }
  &__form-wrap {
    @include container();
  }
}

@media screen and (max-width: 980px) {
  .managers {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 680px) {
  .managers {
    margin-bottom: 0;
    &__form-wrap {
      padding: 0;
    }
    &__form {
      border-radius: 0;
    }
  }
}
