@import 'assets/style/_mixins.scss';

.top-bar {
  background-color: #2058BA;
  margin-bottom: 5px;
  &__container {}
  &__nav {
    padding: 7px 0;
  }
  &__nav-list {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
  }
  &__nav-list-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 25px;
  }
  &__nav-list-icon-wrap {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 6px;
  }
  &__nav-list-icon {}
  &__nav-list-link {
    @include regular();
    font-size: 14px;
    line-height: 1.3;
    color: white;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}