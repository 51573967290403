@import 'assets/style/_mixins.scss';

.popup-wrap {
  display: flex;
  align-items: center;
  justify-content: center;

  &__inner {
    background: #FFFFFF;
    box-shadow: 0px 12px 34px rgba(65, 62, 101, 0.102519);
    border-radius: 10px;
    max-width: 690px;
    width: 100%;
    padding: 50px 55px;
    box-sizing: border-box;
    position: relative;

    .popup-wrap_iir & {
      max-width: 1100px;
    }
    .popup-wrap_logout & {
      max-width: 520px;
    }
  }

  &__close {
    @include reset-button-styles();
    position: absolute;
    right: 18px;
    top: 18px;
  }

  &__icon {
    width: 18px;
    height: 18px;
  }
}

@media screen and (max-width: 1050px) {
  .popup-wrap {
    &__inner {

      .popup-wrap_iir & {
        max-width: 690px;
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .popup-wrap {

    &__inner {
      width: 95%;
      padding: 50px 20px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@media screen and (max-width: 480px) {
  .popup-wrap {

    &__inner {
      padding: 50px 14px;
    }
  }
}
